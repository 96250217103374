"use client";
import React, { useRef, useState, useEffect } from "react";
import { Header } from "./header.interfaces";
import { localeRouteInterface } from "../../utils";
import { FlexContainer } from "../../styles/components";
import { HamburgerButton, Logo, Nav } from "./partials";
import RenderButton from "../Button/partials/Button";
type headerProps = {
	header: Header;
	pageArgs: localeRouteInterface;
	mobileView: boolean;
	module: string;
	translations?: Record<string, boolean>;
	seo: any;
};
const RenderHeaderComponent = ({
	header,
	pageArgs,
	mobileView,
	module,
	translations,
	seo
}: headerProps): JSX.Element => {
	const {
		home,
		links,
		button,
		advanced,
		styling,
		media_src,
		locales,
		searchBar,
		headerButton,
		variant,
		language_picker,
	} = header;

	// force document.title for lazy page switching
	// useEffect(() => {
	// 	document.title = seo?.title;
	// }, [seo]);

	const isConsumer = module == "consumer";
	const integratedNavbar: boolean =
		styling?.background_color === "transparent";
	const navRef = useRef(null);
	const [isNavActive, setIsNavActive] = useState<boolean>(false);
	const [isNavSticked, setIsNavSticked] = useState(mobileView);
	const isNavSticky = variant === "sticky";
	const changeNavStickedMode = () => {
		if (!isNavSticky) return;
		if (mobileView) {
			setIsNavSticked(true);
		} else {
			if (window.scrollY < 400) {
				setIsNavSticked(false);
			} else {
				setIsNavSticked(true);
			}
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", changeNavStickedMode);
		return () => window.removeEventListener("scroll", changeNavStickedMode);
	});

	useEffect(() => {
		// blocks screen from scrolling when menu is open
		if (isNavActive) {
			document.body.classList.add("overflow-y-hidden");
		} else {
			document.body.classList.remove("overflow-y-hidden");
		}
	});

	const toggleMobileNav = async (): Promise<void> => {
		if (isNavActive) {
			mobileNavSlide();
			setTimeout(() => {
				navRef.current.classList.contains("-translate-x-full") &&
					setIsNavActive(false);
			}, 500);
		} else {
			await setIsNavActive(true);
			setTimeout(() => {
				mobileNavSlide();
			}, 10);
		}
	};
	const mobileNavSlide = (): void => {
		navRef.current.classList.toggle("-translate-x-full");
	};

	const headerModeClass = (): string => {
		let classes = "";
		if (integratedNavbar && !isNavSticked) {
			classes += " absolute z-40 !bg-transparent";
		} else {
			classes += " shadow";
		}

		if (isNavSticky && !isNavSticked) {
			classes += " sticky top-0";
		}

		if (isNavSticky && isNavSticked) {
			classes += " fixed";
		}

		return classes;
	};

	return (
		<header
			id={advanced?.anchor_name && advanced.anchor_name}
			className={`left-0 right-0 z-20 mx-auto w-screen transition-all
        ${headerModeClass()}
        text-${styling?.text_color}
        bg-${styling?.background_color}`}
			style={{
				zIndex: 99,
			}}
		>
			<FlexContainer
				$vcenter
				className={`max-w-[1440px] justify-between ${
					isConsumer
						? "md:px-[6.25rem] py-6 px-6"
						: "md:px-20 py-3 px-6"
				}`}
				$full_width
			>
				<div className={"my-auto"} style={{ zIndex: 100 }}>
					<Logo
						link={home}
						media_src={media_src}
						mobileView={mobileView}
						pageArgs={pageArgs}
						isConsumer={isConsumer}
					/>
				</div>
				{mobileView && (
					<div className="flex">
						{headerButton && (
							<div className={"my-auto mr-10 pt-2"}>
								<RenderButton button={headerButton} index={0} />
							</div>
						)}
						<div style={{ zIndex: 100 }} onClick={toggleMobileNav}>
							<HamburgerButton collapsed={isNavActive} />
						</div>
					</div>
				)}
				{(!mobileView || isNavActive) && (
					<nav
						className={`absolute inset-0 flex gap-8 md:relative md:m-0 ${
							isConsumer && "font-sans"
						}`}
					>
						<Nav
							mobileView={mobileView}
							links={links}
							locales={locales}
							pageArgs={pageArgs}
							integrated={integratedNavbar}
							className={"md:!bg-transparent"}
							searchBar={searchBar}
							ref={navRef}
							button={button}
							textShadow={styling.textShadow || false}
							isConsumer={isConsumer}
							enableLanguageSwitcher={language_picker}
							translations={translations}
						/>
					</nav>
				)}
			</FlexContainer>
		</header>
	);
};
export default RenderHeaderComponent;
