"use client";

import React from "react";
import { List, ListItem } from "../list.interfaces";
import { Container, FlexContainer } from "../../../styles/components";
import RenderTextBlock from "../../Text";

const RenderBulletNumber = ({ number }: { number: number }) => {
	return (
		<div className="h-12 w-12 rounded-full bg-grey5 text-center text-[32px] text-white">
			{number}
		</div>
	);
};

const RenderBulletItem = ({
	item,
	index,
}: {
	item: ListItem;
	index: number;
}) => {
	return (
		<div className="md:max- flex w-full items-center gap-8 md:justify-start md:gap-10">
			<div>
				<RenderBulletNumber number={index + 1} />
			</div>
			<div className="mx-2 flex flex-col justify-center">
				<RenderTextBlock data={{ content: item.text }} />
			</div>
		</div>
	);
};

const RenderBulletList = ({ items, advanced, styling, mobileView }: List) => {
	return (
		// eslint-disable-next-line
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className="list mx-auto max-w-2xl"
		>
			<FlexContainer
				$flex_col={true}
				className="my-5 w-full items-center gap-10"
			>
				{items.map((item: ListItem, index: number) => (
					<RenderBulletItem item={item} key={index} index={index} />
				))}
			</FlexContainer>
		</Container>
	);
};

export default RenderBulletList;
