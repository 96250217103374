import React from "react";
import { PageContent } from "../../interfaces/IPage";
import PageBlockMap from "./PageBlockMap";

type PageBlockProps = PageContent;

const RenderPageBlock = ({
	type,
	data,
	mobileView,
	pageArgs,
	extraComponents,
	context,
}: PageBlockProps) => {
	const Fallback = (data) => {
		return (
			<div className="mx-auto my-5 items-center justify-center w-full text-center prose border-2">
				<h1>Block not found</h1>
			</div>
		);
	};

	const blockMap = { ...extraComponents, ...PageBlockMap };

	const Block = blockMap[type] ? blockMap[type].element : Fallback;

	const filteredContext = {};
	if (blockMap[type] && blockMap[type].context) {
		for (const key of Object.keys(context || {})) {
			// Checking if the key is in the whitelist
			if (blockMap[type].context.includes(key)) {
				filteredContext[key] = context[key];
			}
		}
	}
	return (
		<>
			<Block
				data={data}
				mobileView={mobileView}
				pageArgs={pageArgs}
				context={JSON.parse(JSON.stringify(filteredContext))}
			/>
		</>
	);
};

export default RenderPageBlock;
