import { Container } from "../../styles/components/container";
import React from "react";
import { Buttons } from "./buttons.interfaces";
import RenderButton from "./partials/Button";

type buttonProps = {
	data: Buttons;
	className?: string;
};

const RenderButtonsComponent = ({ data, className }: buttonProps) => {
	const { buttons, advanced, styling } = data;
	return (
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className={`buttons ${className} ${
				styling?.sizing == "full" && "w-full"
			} ${styling?.hcenter && "mx-auto"} ${
				styling?.layout === "horizontal" && "flex flex-row gap-4"
			}`}
			$background_color={styling?.background_color}
		>
			{buttons.map((button, index) => (
				<RenderButton key={index} button={button} index={index} />
			))}
		</Container>
	);
};

export default RenderButtonsComponent;
