import { styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)(() => ({
	"&>*:nth-child(2)": {
		borderRadius: "8px",
	},
	"&>label": {
		lineHeight: "unset",
	},
}));
