"use client";

import Link from "next/link";
import React from "react";
import { AllData } from "../../interfaces";
import { quickSuggestions } from "./suggestions.interfaces";

type suggestionProps = {
	data: quickSuggestions;
};

const RenderQuickSuggestionComponent = ({ data }: suggestionProps) => {
	const { advanced, styling, suggestions } = data;

	if (!advanced.enabled) {
		return null;
	}

	return (
		<div className="flex w-full flex-wrap gap-2 md:max-w-[600px] md:gap-4">
			{suggestions.map((keyword, index) => {
				return (
					<Link
						key={index}
						className="flex items-center justify-center rounded-full bg-white px-4 py-2 text-black shadow"
						href={keyword.url}
						prefetch={false}
					>
						<p>{keyword.text}</p>
					</Link>
				);
			})}
		</div>
	);
};

export default RenderQuickSuggestionComponent;
