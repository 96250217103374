"use client";

import React, { useEffect, useRef, useState } from "react";
import { AllData } from "../../interfaces/blocks";
import { getBackGroundColor } from "../../utils";
import { Embed } from "./embed.interfaces";

type embedProps = AllData<Embed>;

const RenderEmbedComponent = ({ data }: embedProps) => {
	const { embeds, advanced, styling } = data;
	const [currentEmbed, setCurrentEmbed] = useState(undefined);

	useEffect(() => {
		embeds && embeds?.length == 1 && setCurrentEmbed(embeds[0]);
	}, []);

	const renderSingleEmbed = () => {
		return (
			<iframe
				src={
					currentEmbed
						? `${currentEmbed.url}?enablejsapi=1&origin=https%3A%2F%2Fnextjs.andrewgosselin.com`
						: null
				}
				title={currentEmbed.label}
				className="h-[700px] w-[900px]"
				frameBorder="0"
			/>
		);
	};

	const updateEmbedSelection = (e) => {
		embeds.forEach((embed) => {
			if (e.target.value == embed.url) {
				setCurrentEmbed(embed);
			}
		});
	};

	const renderEmbedSelect = () => {
		return (
			<select
				value={currentEmbed ? currentEmbed.url : null}
				name={"selectEmbed"}
				onChange={(e) => updateEmbedSelection(e)}
			>
				{embeds
					? embeds.map((embed) => (
							<option value={embed.url}>{embed.label}</option>
					  ))
					: null}
			</select>
		);
	};

	return (
		<div
			id={advanced && advanced.anchor_name ? advanced.anchor_name : ""}
			className="Table block relative my-5 w-full"
			style={getBackGroundColor(
				styling && styling.background_color
					? styling.background_color
					: null
			)}
		>
			<div className="flex relative mx-auto items-center justify-center">
				{currentEmbed ? (
					<>{renderSingleEmbed()}</>
				) : (
					<>{renderEmbedSelect()}</>
				)}
			</div>
		</div>
	);
};

export default RenderEmbedComponent;
