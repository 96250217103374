"use client";

import React, { useState } from "react";
import { Button } from "@mui/material";
import validate_field, { ValidationResponse } from "../validate_field";
import { inputFieldProp } from "../../form.interfaces";
import { StyledTextField } from "../FormStyles";
import {
	CloudUploadOutlined,
	InsertDriveFileOutlined,
} from "@mui/icons-material";

export const RenderFileUploadInputField = ({
	index,
	keyName,
	field,
	setState,
}: inputFieldProp) => {
	const [fieldState, setFieldState] = useState<string>("");
	const [error, setError] = useState<undefined | ValidationResponse>(
		undefined
	);

	const handleUpload = (event) => {
		const file = event.target.files[0];
		const filename = file.name;
		setFieldState(filename);

		const reader = new FileReader();
		reader.onload = () => {
			const base64String = reader.result?.toString().split(",")[1]; // Extract base64 content from data URL
			setState(keyName + "_content", base64String);
			setState(keyName + "_content_filename", filename);
		};
		reader.readAsDataURL(file);
	};

	const RenderDragDropUploader = () => {
		return (
			<div className="m-3 flex flex-col w-full items-center gap-2">
				{fieldState == "" ? (
					<>
						<CloudUploadOutlined fontSize="large" />
						<p className="text-black text-base text-center">
							Drop your file <br /> or <br />
							<input
								className={
									"absolute !opacity-0 inset-0 cursor-pointer"
								}
								type="file"
								accept=".pdf, .doc, .docx, .txt, .rtf"
								id="upload"
								onChange={handleUpload}
								required={!!field.data.validation?.required}
							/>
							<label
								htmlFor="upload"
								className="underline cursor-pointer"
							>
								Browse on your Device
							</label>
						</p>
					</>
				) : (
					<>
						<InsertDriveFileOutlined fontSize="large" />
						<p className="text-black text-base">{fieldState}</p>
					</>
				)}
			</div>
		);
	};

	const InputFieldVariantConfig = {
		normal: {
			endAdornment: (
				<Button
					component="label"
					variant="contained"
					style={{
						background: "#88A91E",
						padding: "4px 16px",
						textTransform: "none",
					}}
				>
					Browse
					<input
						type="file"
						accept=".pdf, .doc, .docx, .txt, .rtf"
						hidden
						onChange={handleUpload}
					/>
				</Button>
			),
		},
		drag_and_drop: {
			inputComponent: RenderDragDropUploader,
		},
	};

	return (
		<StyledTextField
			key={index}
			variant="outlined"
			value={fieldState == "" ? field.data.label : fieldState}
			label={field.data.label}
			multiline={field.data.variant == "normal" ? false : true}
			disabled
			InputProps={InputFieldVariantConfig[field.data.variant]}
			required={!!field.data.validation?.required}
			onBlur={(e) => {
				setError(validate_field(field.data.validation, fieldState));
			}}
			onFocus={(e) => {
				setError(undefined);
			}}
			error={error?.error}
			helperText={
				error?.error
					? error.error_text
					: "File types: pdf, doc, docx, txt, rtf"
			}
			minRows={5}
			fullWidth
		/>
	);
};
