import React from "react";
import RenderTextBlock from "@/components/Text";

export default function AboutUs(data) {
	const baseTextStyle = "text-pretty h-32 [&_strong]:font-forma-djr-micro";
	const textBlocks = [
		{
			key: "Header",
			styling:
				"text-balance top-[55px] md:top-[150px] h-72 left-0 right-0 px-4 [&_h2]:font-forma-djr-micro",
		},
		{
			key: "text1",
			styling: `${baseTextStyle} top-[705px] md:top-[875px] w-3/5 right-0 pr-4 md:p-0 md:-mr-8`,
		},
		{
			key: "text2",
			styling: `${baseTextStyle} top-[1178px] md:top-[1348px] w-1/2 md:mr-8 md:mr-8 md:-ml-8`,
		},
		{
			key: "text3",
			styling: `${baseTextStyle} top-[1655px] md:top-[1820px] w-1/2 right-0 pr-4 md:p-0 md:mr-4`,
		},
		{
			key: "text4",
			styling: `${baseTextStyle} top-[2135px] md:top-[2305px] w-3/5 right-0 pr-4 md:p-0`,
		},
		{
			key: "text5",
			styling: `${baseTextStyle} top-[2610px] md:top-[2780px] w-1/2`,
		},
	];
	return (
		<>
			<div
				className={
					"text-blue3 font-source-sans-pro relative m-auto min-h-[3030px] md:min-h-[3200px] max-w-[440px] px-4 [&_p]:text-[0.925rem]"
				}
			>
				{textBlocks.map(({ key, styling }) => (
					<div key={key} className={`absolute flex ${styling}`}>
						<div className="m-auto">
							<RenderTextBlock
								data={{
									content: data[key],
									styling: { alignment: "center" },
								}}
							/>
						</div>
					</div>
				))}
			</div>
		</>
	);
}
