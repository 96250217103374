"use client";

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
} from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { inputFieldProp } from "../../form.interfaces";
import validate_field, { ValidationResponse } from "../validate_field";

export const RenderCheckBoxField = ({
	index,
	keyName,
	field,
	setState,
}: inputFieldProp) => {
	const [fieldState, setFieldState] = useState(false);
	const [error, setError] = useState<undefined | ValidationResponse>(
		undefined
	);

	const handleChange = (event) => {
		setFieldState(event.target.checked);
		setState(keyName, event.target.checked);
	};

	return (
		<FormControl error={error?.error} fullWidth>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							onChange={handleChange}
							name={keyName}
							onBlur={(e) => {
								setError(
									validate_field(
										field.data.validation,
										fieldState
									)
								);
							}}
							onFocus={(e) => {
								setError(undefined);
							}}
						/>
					}
					label={field.data.label}
					required={field.data.validation?.required ? true : false}
				/>
				{error?.error && (
					<FormHelperText>{error.error_text}</FormHelperText>
				)}
			</FormGroup>
		</FormControl>
	);
};
