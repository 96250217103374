import React from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const RenderYearlyView = ({ component, events }) => {
	const arrayRange = (start, stop, step) =>
		Array.from(
			{ length: (stop - start) / step + 1 },
			(value, index) => start + index * step
		);

	const minYear = new Date(events[0].start).getFullYear();
	const maxYear = new Date(events[events.length - 1].end).getFullYear();
	const yearWidth = 300;
	const monthWidth = yearWidth / 12;
	const eventWidth = 225;

	const renderYears = () => {
		return (
			<div className="flex overflow-x-auto h-auto relative p-3">
				{[...arrayRange(minYear, maxYear, 1)].map((year) => {
					const sortedEvents = events.filter((event) => {
						const startDateTime = new Date(event.start);
						return startDateTime.getFullYear() === year;
					});
					const highestEventsYear = sortedEvents.length;

					return (
						<div key={year} className="py-4 h-full text-sky-400">
							<div>{year}</div>
							<div
								className="flex pl-4"
								style={{
									width: `${yearWidth}px`,
									height: `${200 * highestEventsYear}px`,
								}}
							>
								{[...Array(2)].map((_, monthIndex) => (
									<div
										key={monthIndex}
										className={`w-1/2 h-full text-center py-4 border-solid border-l-2 border-sky-500`}
									></div>
								))}
							</div>
							{sortedEvents.map((event, i) => {
								const startDateTime = new Date(event.start);
								const xPosition =
									yearWidth *
										(startDateTime.getFullYear() -
											minYear) +
									monthWidth * startDateTime.getMonth();
								const yPosition = i === 0 ? 15 : (i + 1) * 25;

								return (
									<div
										key={event.start + event.title}
										className={`rounded-lg p-3 my-2 absolute shadow`}
										style={{
											maxWidth: `${eventWidth}px`,
											backgroundColor:
												event.background_color,
											top: `${yPosition}%`,
											left: `${xPosition}px`,
											color: event.text_color,
										}}
									>
										<div>
											<div className="flex items-center">
												{event.icon && (
													<div className="rounded-full w-9 h-9 overflow-hidden flex-none mr-2">
														<img
															src={event.icon}
															alt="user image"
														/>
													</div>
												)}
												<p>
													<span className="text-normal font-semibold text-gray-900">
														{event.title}
													</span>
												</p>
											</div>
											<div className="font-normal">
												{event.text}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	};

	return <div>{renderYears()}</div>;
};

export default function YearlyView({ component, events }) {
	return (
		<div className="p-4">
			<RenderYearlyView component={component} events={events} />
		</div>
	);
}
