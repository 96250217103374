export const computeRowHeight = (
	mobileView: boolean,
	rowSizing: string | undefined
): string => {
	const customSizingRegex = new RegExp(".*.px$");

	const sizingMap = {
		auto: "auto",
		xs: mobileView ? "72px" : "84px",
		sm: mobileView ? "80px" : "115px",
		md: mobileView ? "156px" : "225px",
		lg: "400px",
	};

	const fallback =
		rowSizing && rowSizing.match(customSizingRegex)
			? rowSizing
			: sizingMap["auto"];

	return sizingMap[rowSizing] ? sizingMap[rowSizing] : fallback;
};
