import React from "react";

export const formatMarkdown = (text) => {
	return text.split(/(\*\*[^*]+\*\*)/g).map((part, index) => {
		if (part.startsWith("**") && part.endsWith("**")) {
			return <strong key={index}>{part.slice(2, -2)}</strong>;
		}
		return part;
	});
};

export const sanitizeForUrl = (name) => {
	return name
		.toLowerCase() // Convert to lowercase
		.replace(/[^a-z0-9]+/g, "-") // Replace spaces and special characters with hyphens
		.replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
};
