import RenderAnimatedPlayerComponent from "@/components/AnimatedPlayer";
import ToteHeavyAnimation from "../../../../../public/assets/consumer/lottie/tote_heavy.json";
import React from "react";
import { CtaContent } from "./text";
import RenderButtonsComponent from "@/components/Button";

const Cta = ({ lang }) => {
	const buttonsData = [
		{
			text: CtaContent.button.text[lang],
			link: CtaContent.button.link[lang],
			target: "_blank",
			styling: {
				sizing: "free",
				background_color: "red1",
				rounded: false,
				display: "mobile",
				text_color: "white",
				padded: true,
				border_color: "red1",
			},
		},
		{
			link: "https://app.adjust.com/nzpq02?campaign=null&amp;adgroup=",
			target: "_blank",
			styling: {
				rounded: false,
				sizing: "md",
				padded: false,
				display: "desktop",
				background_image: `https://d2jxuf8ovdiw8x.cloudfront.net/uploads/sites/18/2024/09/appstore_${lang}.svg`,
			},
		},
		{
			link: "https://app.adjust.com/bn65k1?campaign=null&amp;adgroup=",
			target: "_blank",
			styling: {
				rounded: false,
				sizing: "md",
				padded: false,
				display: "desktop",
				background_image: `https://d2jxuf8ovdiw8x.cloudfront.net/uploads/sites/18/2024/09/googleplay_${lang}.svg`,
			},
		},
	];
	return (
		<div className="justify-center flex gap-4 md:gap-28 md:py-24 py-14">
			<div className="w-40 h-28 md:w-[400px] md:h-72 flex justify-center">
				<RenderAnimatedPlayerComponent
					data={{ animation_data: ToteHeavyAnimation }}
				/>
			</div>
			<div className="flex flex-col md:gap-6 gap-2.5 items-start justify-center">
				<div className={"font-bold text-xl md:text-3xl"}>
					{CtaContent.title[lang]}
				</div>
				<RenderButtonsComponent data={{ buttons: buttonsData }} />
			</div>
		</div>
	);
};

export default Cta;
