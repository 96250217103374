"use client";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useMemo } from "react";
import { apply_text } from "./text";
import { getBounds } from "@/utils/locAndMap";

const RenderMap = ({ data, locale = "en", locations, isMapLoaded, states }) => {
	const mapOptions = useMemo<google.maps.MapOptions>(
		() => ({
			clickableIcons: true,
			scrollwheel: false,
			streetViewControl: false,
			disableDoubleClickZoom: true,
			zoom: 15,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_CENTER,
			},
			mapTypeControl: false,
		}),
		[]
	);

	if (isMapLoaded) {
		return (
			<GoogleMap
				options={mapOptions}
				zoom={states.mapZoom.value}
				mapTypeId={google.maps.MapTypeId.ROADMAP}
				onClick={() => {
					states.activeLocation.set(null);
				}}
				mapContainerStyle={{
					width: "100%",
				}}
				mapContainerClassName={`rounded w-full h-[327px] h-full`}
				onLoad={(mapRef) => {
					states.map.set(mapRef);
					// Set map bounds to fit all markers
					mapRef.fitBounds(getBounds(locations));
				}}
			>
				{locations.map((location, index) => (
					<MarkerF
						key={index}
						icon={
							location?.icon_override
								? location?.icon_override
								: data?.styling?.default_icon
						}
						position={{ lat: location.lat, lng: location.lng }}
						onClick={() => {
							states.mapZoom.set(12);
							states.map.value.panTo({
								lat: location.lat,
								lng: location.lng,
							});
							states.activeLocation.set(location);
						}}
						animation={
							states.activeLocation.value &&
							states.activeLocation.value.title === location.title
								? google.maps.Animation.BOUNCE
								: undefined
						}
					/>
				))}
				{states?.selectedPlace?.value && (
					<MarkerF
						position={{
							lat: states.selectedPlace.value.lat,
							lng: states.selectedPlace.value.lng,
						}}
					/>
				)}
				{states?.activeLocation?.value && (
					<div
						className="absolute p-2 w-full"
						style={{ bottom: "0px", left: "0px" }}
					>
						<div className="rounded w-full bg-grey1 p-4 relative">
							<div className="flex-1">
								<h3 className="m-0 text-xl text-black">
									{states.activeLocation.value.title}
								</h3>
								<p className="m-0 text-base text-grey4">
									{states.activeLocation.value.address1}
								</p>
								<p className="m-0 text-base text-grey4">
									{states.activeLocation.value.address2}
								</p>
							</div>
							<div className="absolute right-4 bottom-6">
								<a
									className="self-end bg-red1 text-white px-8 py-2.5 rounded-lg"
									href={states.activeLocation.value.url}
								>
									{data?.location_button_text
										? data.location_button_text
										: apply_text[locale]}
								</a>
							</div>
						</div>
					</div>
				)}
			</GoogleMap>
		);
	}
};

export default RenderMap;
