"use client";
import React, { useMemo, useState } from "react";
import { Map } from "./map.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faMap } from "@fortawesome/free-solid-svg-icons";
import { localeRouteInterface } from "../../utils";
import RenderMap from "./partials/Map";
import RenderList from "./partials/List";
import { useLoadScript } from "@react-google-maps/api";
import { list_view_text, map_view_text, search_text } from "./partials/text";
import { getFilteredLocs } from "@/utils/locAndMap";
import { RenderAddressSearch } from "@/components/AddressSearch";

const { library } = require("@fortawesome/fontawesome-svg-core");
library.add(faList);

type props = {
	data: Map;
	mobileView: boolean;
	context?: any;
	pageArgs: localeRouteInterface;
};

const RenderMapComponent = ({ data, mobileView, context, pageArgs }: props) => {
	const [map, setMap] = useState<any>(null);
	const [selectedPlace, setSelectedPlace] = useState(null);
	const [activeLocation, setActiveLocation] = useState(null);
	const [mapZoom, setMapZoom] = useState(14);
	const [isMapView, setIsMapView] = useState(true);

	const locale = pageArgs?.locale?.language;

	const libraries = useMemo(() => ["places"], []);
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: "AIzaSyAohftA6_ox8J_cauamH33mBcFjT-AnncE",
		libraries: libraries as any,
	});

	const filteredLocs = getFilteredLocs(
		context?.locations?.data,
		data.category,
		data.market,
		selectedPlace
	);

	if (isLoaded) {
		return (
			<div className="flex flex-col md:gap-4 md:flex-row">
				<div
					className={`w-full md:w-7/12 h-[400px] md:h-[500px] flex flex-col ${
						isMapView ? "block" : "hidden md:block"
					}`}
				>
					{mobileView && (
						<div className={"mb-4"}>
							<RenderAddressSearch
								locations={filteredLocs}
								placeholder={search_text[locale]}
								variant={"map"}
								states={{
									selectedPlace: {
										value: selectedPlace,
										set: setSelectedPlace,
									},
									map: {
										value: map,
										set: setMap,
									},
									mapZoom: {
										value: mapZoom,
										set: setMapZoom,
									},
								}}
							/>
						</div>
					)}
					<RenderMap
						data={data}
						locale={locale}
						locations={filteredLocs}
						isMapLoaded={isLoaded}
						states={{
							activeLocation: {
								value: activeLocation,
								set: setActiveLocation,
							},
							selectedPlace: {
								value: selectedPlace,
								set: setSelectedPlace,
							},
							map: {
								value: map,
								set: setMap,
							},
							mapZoom: {
								value: mapZoom,
								set: setMapZoom,
							},
						}}
					/>
				</div>

				<div
					className={`w-full md:w-5/12 h-[400px] md:h-[500px] ${
						!isMapView ? "block" : "hidden md:block"
					}`}
				>
					<div className={`h-full flex flex-col`}>
						<div className={"mb-4"}>
							<RenderAddressSearch
								locations={filteredLocs}
								placeholder={search_text[locale]}
								variant={"map"}
								states={{
									selectedPlace: {
										value: selectedPlace,
										set: setSelectedPlace,
									},
									map: {
										value: map,
										set: setMap,
									},
									mapZoom: {
										value: mapZoom,
										set: setMapZoom,
									},
								}}
							/>
						</div>
						<RenderList locale={locale} locations={filteredLocs} />
					</div>
				</div>
				{mobileView && (
					<button
						className={`md:hidden mt-6 mx-auto text-white py-2.5 px-8 bg-green1 rounded-lg`}
						onClick={() => setIsMapView(!isMapView)}
					>
						<FontAwesomeIcon
							icon={isMapView ? faList : faMap}
							className="text-gray-500 pr-2"
						/>
						{isMapView
							? map_view_text[pageArgs?.locale?.language || "en"]
							: list_view_text[
									pageArgs?.locale?.language || "en"
							  ]}
					</button>
				)}
			</div>
		);
	}
};

export default RenderMapComponent;
