"use client";

import React, { useEffect, useRef } from "react";
import { Slider } from "./slider.interfaces";
import "./slider.styles.css";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import RenderCardComponent from "../Card";
import { getTailwindScreenSize } from "../../utils/tailwindScreensize";
import { computeRowHeight } from "../../utils";
import { Box } from "@mui/material";
import RenderMediaComponent from "../Media";
import { Container } from "../../styles/components";
import { NavigationButtons } from "./partials/Navigation";

type SliderProps = {
	data: Slider;
	mobileView: boolean;
};

const sliderAlignMap: Record<"left" | "center" | "right", string> = {
	left: "prev",
	center: "center",
	right: "next",
};

const RenderSliderItem = ({
	element,
	rowSizing,
	mobileView,
}: {
	element: any;
	rowSizing: string;
	mobileView: boolean;
}) => {
	if (element.data.styling && rowSizing != "auto") {
		element.data.styling.sizing = "full";
	}

	if (element.type == "card") {
		return (
			<RenderCardComponent data={element.data} mobileView={mobileView} />
		);
	} else if (element.type == "media") {
		return (
			<RenderMediaComponent data={element.data} mobileView={mobileView} />
		);
	}

	return null;
};

const RenderSliderComponent = ({ data, mobileView }: SliderProps) => {
	const { items, advanced, styling } = data;
	const itemsPerRowMap = styling?.itemsPerRow || {
		xs: 2,
		sm: 2,
		md: 4,
		lg: 4,
	};

	const placeholderItems = [
		{
			type: "card",
			data: {
				content: "Loading...",
				styling: {
					no_padding: true,
					media_sizing: {
						desktop: {
							width: "w-full",
							height: "md:h-52 h-36",
						},
					},
				},
			},
		},
	];

	let itemSizing = mobileView
		? styling?.itemSizing?.mobile
		: styling?.itemSizing?.desktop;
	const rowHeight = computeRowHeight(mobileView, itemSizing);

	const screenSize = getTailwindScreenSize(mobileView);
	const itemsPerRow = itemsPerRowMap[screenSize];
	const itemWidth = styling?.itemWidth
		? styling.itemWidth
		: 100 / itemsPerRow;
	const itemWidthMetric = styling?.itemWidth ? "px" : "%";

	const isInteractionEnabled = items.length > itemsPerRow;
	const showArrows = isInteractionEnabled && styling?.hasArrows;

	const sliderAlignment = styling?.alignment
		? sliderAlignMap[styling?.alignment]
		: "prev";

	const flickingRef = useRef(null);
	if (flickingRef.current) {
		mobileView && flickingRef.current.resize();

		if (isInteractionEnabled) {
			flickingRef.current.enableInput();
		} else {
			flickingRef.current.disableInput();
			flickingRef.current.destroy();
		}
	}

	useEffect(() => {
		const flicking = flickingRef.current;
		if (!flicking) return;

		const handleWheel = (event) => {
			if (Math.abs(event.deltaX) > 0) {
				event.preventDefault();

				if (Math.abs(event.deltaY) > 15 || Math.abs(event.deltaX) < 5)
					return;

				const minPos = flicking.camera.range.min;
				const currentPos = flicking.camera.position;
				const maxPos =
					flicking.camera.range.max -
					flicking.viewport.width +
					flicking.camera.size;
				const newPos = currentPos + event.deltaX;

				flicking.camera.lookAt(
					Math.max(minPos, Math.min(newPos, maxPos))
				);
				flicking.control.updateInput();
			}
		};

		const flickingElement = flickingRef.current?._viewportElement;
		flickingElement &&
			flickingElement.addEventListener("wheel", handleWheel);

		// Cleanup
		return () => {
			flickingElement &&
				flickingElement.removeEventListener("wheel", handleWheel);
		};
	}, []);

	return (
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className="slider w-full"
			$background_color={styling?.background_color}
		>
			<Box
				sx={{
					".flicking-viewport .MuiCard-root": {
						height: styling?.hasBullets
							? "auto"
							: "100% !important",
					},
				}}
			>
				{showArrows && !mobileView && (
					<NavigationButtons
						sliderPrev={() =>
							flickingRef.current?.camera.position >
								flickingRef.current?.camera.range.min &&
							flickingRef.current?.prev()
						}
						sliderNext={() =>
							flickingRef.current?.camera.position <
								flickingRef.current?.camera.range.max &&
							flickingRef.current?.next()
						}
					/>
				)}

				<div className={`flex w-full flex-col`}>
					<Flicking
						align={sliderAlignment}
						ref={flickingRef}
						bound={true} // Set bound to true to enable edge bounce
						bounce={0.5} // Adjust the bounce factor
						deceleration={0.02} // Adjust the deceleration factor
						circular={false}
					>
						{items.length > 0 &&
							items.map((element, index) => (
								<div
									key={index}
									style={{
										height: rowHeight,
										width: `calc((100% - ${
											itemsPerRow - 1
										}rem) / ${itemsPerRow})`,
									}}
									className={`mr-4 ${
										styling?.hasBullets && "pl-4"
									} min-w-48`}
								>
									{styling?.hasBullets && (
										<div className="ml-6 pb-8 pt-3 relative">
											<div
												key={index}
												className={`bullet bg-grey2`}
											></div>
											<div
												key={index + "line"}
												className={`bullet-line bg-grey3 ml-[18px] ${
													index < items.length - 1
														? ""
														: "last-line"
												}`}
												style={{
													width: `calc(100% + 32px)`,
													position: "absolute",
												}}
											></div>
										</div>
									)}

									<RenderSliderItem
										element={element}
										rowSizing={"3"}
										mobileView={mobileView}
									/>
								</div>
							))}

						{items.length == 0 && (
							<div
								key={0}
								style={{
									height: rowHeight,
									width: `calc((100% - ${
										itemsPerRow - 1
									}rem) / ${itemsPerRow})`,
								}}
								className={`mr-4 ${
									styling?.hasBullets && "pl-4"
								}`}
							>
								{styling?.hasBullets && (
									<div className="ml-6 pb-8 pt-3">
										<div
											key={0}
											className={`bullet bg-grey2`}
										></div>
										<div
											key={0 + "line"}
											className={`bullet-line bg-grey3 ${
												0 < items.length - 1
													? ""
													: "last-line"
											}`}
											// If the itemWidth is set, then it needs to get precentages of the number instead
											style={{
												marginLeft: `${
													(10 / 100) * itemWidth
												}${itemWidthMetric}`,
												width: `${
													itemWidth -
													(12 / 100) * itemWidth
												}${itemWidthMetric}`,
												position: "absolute",
											}}
										></div>
									</div>
								)}

								<RenderMediaComponent
									data={{
										media_src: {
											desktop: {
												src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAACTpAAAk6QFQJOf4AAAAMklEQVR4nAEnANj/ALmGZeCvj//45e/k0gD0vpudf3OIion/89oAcIJ6GDVSBgQR7cDF4cYWH0h5svQAAAAASUVORK5CYII=",
												type: "image",
											},
										},
									}}
									mobileView={mobileView}
								/>
							</div>
						)}
					</Flicking>
				</div>
			</Box>
		</Container>
	);
};

export default RenderSliderComponent;
