import React from "react";
import { inputFieldProp, FieldIdData } from "../../form.interfaces";
import {
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
} from "@mui/material";

export const RenderRadioField = ({
	index,
	keyName,
	field,
	setState,
}: inputFieldProp) => {
	const fieldData = field.data;
	return (
		<FormControl key={index} fullWidth>
			<FormLabel id={keyName}>{field.data.label}</FormLabel>
			<RadioGroup
				row={fieldData.variant == "row" ? true : false}
				name={keyName}
			>
				{fieldData.options!.map(
					(option: FieldIdData, index: number) => {
						return (
							<FormControlLabel
								key={index}
								value={option.key_name}
								control={<Radio />}
								label={option.label}
								onChange={(e) =>
									setState(keyName, option.key_name)
								}
							/>
						);
					}
				)}
			</RadioGroup>
		</FormControl>
	);
};
