import tw from "tailwind-styled-components";

export const TilesContainer = tw.div`
  grid 
  gap-4
  max-w-screen-xl
  px-6
  py-12
  grid-cols-[repeat(auto-fit,minmax(288px,1fr))]
  items-center
  md:mx-auto
  transition-all
  duration-1000 
  ease-in-out
  delay-200
  translate-x-1/2
`;

type TileProps = {
	$bg_img?: string;
};

export const Tile = tw.div<TileProps>`  
  w-full
  md:w-72
  h-52 
  p-4
  pb-3
  rounded 
  shadow-sm
  flex
  flex-col
  justify-end
  relative
  overlay
  duration-400
  ease-in-out
  transition-all
  prose-p:text-ellipsis
  prose-p:line-clamp-1
`.withStyle((p) =>
	p.$bg_img
		? {
				backgroundImage: `url(${p.$bg_img})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center",
		  }
		: {}
);
