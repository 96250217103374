"use client";
import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { filterState, Job, JobList } from "../joblist.interfaces";
import { getFilterObject } from "./getFilterObject";
import { getJobKeyFromNestedFilter } from "./getJobKeyFromNestedFilter";
import RenderNestedCheckbox from "./RenderNestedCheckbox";

type FilterProps = {
	filters: JobList["filters"];
	setter: React.Dispatch<React.SetStateAction<filterState>>;
	state: filterState;
	jobs: Job[];
};

const RenderFilters = ({ setter, filters, state, jobs }: FilterProps) => {
	const renderFilterCheckbox = (filter: string) => {
		const filterSet = new Set(
			jobs.map((job) => getJobKeyFromNestedFilter(job, filter))
		);
		const uniqueFilterList = [...filterSet].filter((n) => n);
		const filterObjectCount: Record<string, number> = getFilterObject(
			jobs.map((job) => getJobKeyFromNestedFilter(job, filter))
		);
		const handleChange = (event) => {
			const value = event.target.value;
			if (event.target.checked) {
				state[filter].push(value);
				const newState = {
					...state,
					[filter]: state[filter],
				};
				setter(newState);
			} else {
				state[filter].splice(state[filter].indexOf(value), 1);
				const newState = {
					...state,
					[filter]: state[filter],
				};
				setter(newState);
			}
		};

		return (
			<>
				{uniqueFilterList.map((filterValue, index) => (
					<FormControlLabel
						key={index}
						sx={{ alignItems: "flex-start" }}
						control={
							<Checkbox
								value={filterValue}
								onChange={handleChange}
								size="small"
								className="!py-0"
								checked={state[filter].includes(filterValue)}
							/>
						}
						label={`${filterValue} (${
							filterObjectCount[filterValue] ?? "0"
						})`}
					/>
				))}
			</>
		);
	};

	return (
		<>
			{filters.map((filter, index) => (
				<div key={index} className="flex flex-col gap-1">
					<strong>{filter.filter_header}</strong>
					{filter.filter_target.includes("location")
						? RenderNestedCheckbox(
								filter.filter_target,
								setter,
								state,
								jobs
						  )
						: renderFilterCheckbox(filter.filter_target)}
				</div>
			))}
		</>
	);
};

export default RenderFilters;
