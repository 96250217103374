// Calculate bounds to fit all markers
export const getBounds = (locations) => {
	try {
		const bounds = new window.google.maps.LatLngBounds();
		locations.forEach((location) => {
			bounds.extend(
				new window.google.maps.LatLng(location.lat, location.lng)
			);
		});
		return bounds;
	} catch (e) {
		console.error("Error calculating bounds:", e);
		// Return default bounds for Central Europe
		const centralEuropeBounds = {
			north: 53.5, // Northern limit (Lat) - Covers northern Germany and Netherlands
			south: 47.0, // Southern limit (Lat) - Covers southern Germany and northern France
			west: 1.5, // Western limit (Lng) - Covers western France
			east: 15.0, // Eastern limit (Lng) - Covers eastern Germany and eastern Netherlands
		};
		return centralEuropeBounds;
	}
};

/**
 * Calculates the distance between two coordinates using the Haversine formula.
 *
 * @param {{lat: number, lng: number}} coord1 - The first coordinate.
 * @param {{lat: number, lng: number}} coord2 - The second coordinate.
 * @returns {number} - The distance between the two coordinates in kilometers.
 */
const haversineDistance = (coord1, coord2) => {
	const toRadians = (degrees) => (degrees * Math.PI) / 180;

	const R = 6371; // Radius of the Earth in kilometers
	const lat1 = toRadians(coord1.lat);
	const lon1 = toRadians(coord1.lng);
	const lat2 = toRadians(coord2.lat);
	const lon2 = toRadians(coord2.lng);

	const dLat = lat2 - lat1;
	const dLon = lon2 - lon1;

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(lat1) *
			Math.cos(lat2) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	return R * c; // Distance in kilometers
};

export const getFilteredLocs = (
	locations = [],
	category = "fc",
	market = "nl",
	selectedPlace = null,
	blacklist = [],
	whitelist = []
) => {
	return locations
		.filter((location: any) => {
			if (category == "fc") {
				return location.config.fc.active == true;
			} else if (category == "hub") {
				return location.config.hub.active == true;
			}
			return false;
		})
		.filter((location: any) => {
			if (blacklist.length > 0) {
				return !blacklist.includes(
					location.config[category]?.abbreviation
				);
			}
			return true;
		})
		.filter((location: any) => {
			if (whitelist.length > 0) {
				return whitelist.includes(
					location.config[category]?.abbreviation
				);
			}
			return true;
		})
		.filter((location: any) => location.market == market)
		.map((location: any) => {
			return {
				title: location.name,
				address1: `${location.location.street} ${location.location.house_number}`,
				address2: `${location.location.zip_code} ${location.location.city}`,
				url: `?location=${encodeURIComponent(location.name)}`,
				lat: parseFloat(location.location?.latitude || 0),
				lng: parseFloat(location.location?.longitude || 0),
				disabled: location.config[category]?.disable_location_selection,
				watchlist: location.config[category]?.is_watchlist,
				manyVacancies: location.config[category]?.many_vacancies,
				abbreviation: location.config[category]?.abbreviation,
				dist: selectedPlace
					? haversineDistance(
							{
								lat: parseFloat(
									location.location?.latitude || 0
								),
								lng: parseFloat(
									location.location?.longitude || 0
								),
							},
							{
								lat: selectedPlace.lat,
								lng: selectedPlace.lng,
							}
					  )
					: 0,
			};
		});
};

export const getSortedLocs = (locations = []) => {
	return locations.sort((a, b) => a.dist - b.dist);
};
