export const activePrepTime = {
	nl: "min bereiden",
	de: "Min. Zubereitung",
	en: "min Preparation",
	fr: "min Préparation",
};

export const totalPrepTime = {
	nl: "min totaal",
	de: "min gesamt",
	en: "min total",
	fr: "min total",
};

export const BackToCookbook = {
	nl: "Alle recepten",
	de: "Alle Rezepte",
	fr: "Toutes les recettes",
	en: "All recipes",
};

export const StickyBannerContent = {
	title: {
		nl: "Lijkt dit recept je lekker?",
		de: "Wäre das was für dich?",
		fr: "On s’occupe du menu ?",
		en: "Liked this recipe?",
	},
	subtitle: {
		nl: "Laat alle ingrediënten vers bezorgen!",
		de: "Lass dir alles frisch liefern!",
		fr: "Livraison gratuite de vos recettes !",
		en: "Check out the entire catalogue!",
	},
	button: {
		text: {
			desktop: {
				nl: "Download onze app",
				de: "Hol dir unsere App",
				fr: "Télécharger l’appli",
				en: "Download our app",
			},
			mobile: {
				nl: "Kijk",
				de: "Zuur app",
				fr: "Voir",
				en: "View",
			},
		},
		link: {
			nl: "/nl/app-downloaden",
			de: "/de/app-herunterladen",
			fr: "/fr/telecharger-app",
			en: "/",
		},
	},
};

export const CtaContent = {
	title: {
		nl: "Picnic proberen?",
		de: "Picnic ausprobieren?",
		fr: "Essayez Picnic !",
		en: "Try Picnic!",
	},
	button: {
		text: {
			nl: "Download onze app",
			de: "App herunterladen",
			fr: "Télécharger l’appli",
			en: "Download our app",
		},
		link: {
			nl: "/app-downloaden",
			de: "/app-herunterladen",
			fr: "/telecharger-app",
			en: "/app-downloaden",
		},
	},
};

export const ingredients = {
	nl: "Ingrediënten",
	de: "Zutaten",
	fr: "Ingrédients",
	en: "Ingredients",
};

export const instructions = {
	nl: "Bereiding",
	de: "Zubereitung",
	fr: "Instructions",
	en: "Instructions",
};

export const addSelfIngredients = {
	nl: "Waarschijnlijk al in huis",
	de: "Aus der eigenen Küche",
	fr: "Probablement dehors",
	en: "Probably in the house",
};
