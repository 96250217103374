export const HamburgerButton = ({ collapsed }) => {
	return (
		<>
			<label
				className="group flex h-full items-center"
				htmlFor="sidebar-collapse"
			>
				<div className="flex h-[20px] w-[20px] flex-col justify-center gap-1">
					<div
						className={`w-5 origin-left transform rounded border border-solid bg-black transition-all duration-300 ${
							collapsed &&
							"w-6 -translate-y-[3.9px] rotate-[42deg]"
						}`}
					></div>
					<div
						className={`w-5 transform rounded border border-solid bg-black transition-all duration-300  ${
							collapsed && "w-6 opacity-0"
						}`}
					></div>
					<div
						className={`w-5 origin-left transform rounded border border-solid bg-black transition-all duration-300 ${
							collapsed && "w-6 -rotate-[42deg]"
						}`}
					></div>
				</div>
			</label>
		</>
	);
};
