export const htmlspecialchars_decode = (str) => {
	const entities = {
		"&amp;": "&",
		"&lt;": "<",
		"&gt;": ">",
		"&quot;": '"',
		"&#039;": "'",
		"&#x2F;": "/",
		"&#x3D;": "=",
	};

	const pattern = new RegExp(Object.keys(entities).join("|"), "g");

	return str.replace(pattern, (match) => entities[match]);
};
