import dynamic from "next/dynamic";

const PageBlockMap = {
	text: {
		element: dynamic(() => import("../Text"), { ssr: true }),
		context: [],
	},
	media: {
		element: dynamic(() => import("../Media"), { ssr: true }),
		context: [],
	},
	list: {
		element: dynamic(() => import("../List"), { ssr: false }),
		context: [],
	},
	form: {
		element: dynamic(() => import("../Form"), { ssr: false }),
		context: ["forms", "locations"],
	},
	buttons: {
		element: dynamic(() => import("../Button"), { ssr: false }),
		context: [],
	},
	embed: {
		element: dynamic(() => import("../Embed"), { ssr: false }),
		context: [],
	},
	slider: {
		element: dynamic(() => import("../Slider"), { ssr: false }),
		context: [],
	},
	animated_player: {
		element: dynamic(() => import("../AnimatedPlayer"), { ssr: false }),
		context: [],
	},
	calendar: {
		element: dynamic(() => import("../Calendar"), { ssr: false }),
		context: [],
	},
	search_bar: {
		element: dynamic(() => import("../SearchBar"), { ssr: false }),
		context: ["jobs"],
	},
	quickSuggestions: {
		element: dynamic(() => import("../QuickSuggestions"), { ssr: false }),
		context: ["jobs"],
	},
	calculator: {
		element: dynamic(() => import("../Calculator"), { ssr: false }),
		context: [],
	},
	map: {
		element: dynamic(() => import("../Map"), { ssr: false }),
		context: ["locations"],
	},
	accordion: {
		element: dynamic(() => import("../Accordion"), { ssr: false }),
		context: [],
	},
	card: {
		element: dynamic(() => import("../Card"), { ssr: false }),
		context: [],
	},
	joblist: {
		element: dynamic(() => import("../JobList"), { ssr: false }),
		context: ["jobs"],
	},
	grid: {
		element: dynamic(() => import("../Grid"), { ssr: false }),
		context: [],
	},
	tile: {
		element: dynamic(() => import("../Tiles"), { ssr: false }),
		context: [],
	},
	sticky_row: {
		element: dynamic(() => import("../StickyRow"), { ssr: false }),
		context: [],
	},
	events: {
		element: dynamic(() => import("../Events"), { ssr: false }),
		context: ["events"],
	},
	banner: {
		element: dynamic(() => import("../Banner"), { ssr: false }),
		context: [],
	},
	contacts: {
		element: dynamic(() => import("../Contacts"), { ssr: false }),
		context: [],
	},
};

export default PageBlockMap;
