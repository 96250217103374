import { Checkbox, FormControlLabel } from "@mui/material";
import {
	CheckboxModel,
	getCheckboxVariantModel,
	useCheckboxTreeSync,
} from "checkbox-tree-sync";
import React, { useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
	Job,
	filterState,
	job_location,
	nestedFilterArray,
} from "../joblist.interfaces";
import { getNestedLocationList } from "./getNestedCheckboxList";
import { getFilterObject } from "./getFilterObject";
import { getJobKeyFromNestedFilter } from "./getJobKeyFromNestedFilter";

const RenderNestedCheckboxTree = ({
	tree,
	locationCount,
}: {
	tree: CheckboxModel[];
	locationCount: Record<string, number>;
}) => {
	const [expanded, setExpandedState] = useState<Array<any>>([]);

	const toggleExpanded = (value) => {
		setExpandedState((_expadedState) => {
			if (_expadedState.includes(value)) {
				return _expadedState.filter((c) => c !== value);
			} else {
				return [..._expadedState, value];
			}
		});
	};

	return (
		<ul className={"list-none"}>
			{tree.map((checkbox, index) => {
				return (
					<li key={`${checkbox.id}-${index}`}>
						<div>
							<FormControlLabel
								sx={{
									alignItems: "flex-start",
									marginRight: "0px",
								}}
								control={
									<Checkbox
										checked={!!checkbox.getIsChecked()}
										onChange={checkbox.getToggleHandler()}
										size="small"
										className="!py-0"
										indeterminate={
											!!checkbox.getIsChecked() &&
											checkbox.getVariant() ===
												"indeterminate"
										}
									/>
								}
								label={`${checkbox.id} (${
									locationCount[checkbox.id] ?? "0"
								})`}
							/>
							{checkbox.subCheckboxes?.length > 0 &&
								(!expanded.includes(checkbox.id) ? (
									<KeyboardArrowDown
										onClick={(e) =>
											toggleExpanded(checkbox.id)
										}
									/>
								) : (
									<KeyboardArrowUp
										onClick={(e) =>
											toggleExpanded(checkbox.id)
										}
									/>
								))}
							{checkbox.subCheckboxes?.length > 0 &&
								expanded.includes(checkbox.id) && (
									<div className="pl-4">
										<RenderNestedCheckboxTree
											tree={checkbox.subCheckboxes}
											locationCount={locationCount}
										/>
									</div>
								)}
						</div>
					</li>
				);
			})}
		</ul>
	);
};

const RenderNestedCheckbox = (
	filter: string,
	setter: React.Dispatch<React.SetStateAction<filterState>>,
	checkState: filterState,
	jobs: Job[]
) => {
	const [state, setState] = useState({});
	const locationList: Array<job_location> = [];
	jobs.forEach((job) => {
		const { location } = job.data;
		locationList.push(location);
	});
	const nestedfilterList: nestedFilterArray =
		getNestedLocationList(locationList);
	const countryCount = getFilterObject(
		jobs.map((job) =>
			getJobKeyFromNestedFilter(job, "data.location.country")
		)
	);
	const cityCount = getFilterObject(
		jobs.map((job) => getJobKeyFromNestedFilter(job, "data.location.city"))
	);
	const stateCount = getFilterObject(
		jobs.map((job) => getJobKeyFromNestedFilter(job, "data.location.state"))
	);
	const locationCount: Record<string, number> = {
		...countryCount,
		...cityCount,
		...Object.fromEntries(
			Object.entries(stateCount).filter(([key]) => !(key in countryCount)) //take out countrys from states count
		),
	};

	const isCity = (location: string): boolean => {
		return locationList.some((loc) => {
			return loc.city == location;
		});
	};

	const convertNestedStateToFilterState = (
		value: Record<string, boolean>
	) => {
		setState(value);
		const filterState: string[] = [];
		Object.keys(value).forEach((location) => {
			isCity(location) && filterState.push(location);
		});

		const newState = {
			...checkState,
			[filter]: filterState,
		};
		setter(newState);
	};

	const { checkBoxTreeSync } = useCheckboxTreeSync({
		data: nestedfilterList,
		state,
		onStateChange: convertNestedStateToFilterState,
		getCheckboxId: (item) => `${item.name}`,
		getSubCheckboxes: (item) => item.children,
		getCheckboxVariantModel: getCheckboxVariantModel(),
	});

	return (
		<RenderNestedCheckboxTree
			tree={checkBoxTreeSync.getCheckboxesModel().checkboxes}
			locationCount={locationCount}
		/>
	);
};

export default RenderNestedCheckbox;
