import { filter, filterState, pre_filter } from "../joblist.interfaces";

export const createFilterCheckState = (
	filters: filter[],
	pre_filters: pre_filter[]
): filterState => {
	let filterState = {};
	filters.forEach((filter) => {
		filterState[filter.filter_target] = populatePreFilters(
			filter,
			pre_filters
		);
	});
	// add pre-filterstates if filter targets and pre-filter targets are different
	pre_filters.forEach((pre_filter) => {
		if (!Object.keys(filterState).includes(pre_filter.filter_target)) {
			filterState[pre_filter.filter_target] = pre_filter.filter_values;
		}
	});
	return filterState as filterState;
};

const populatePreFilters = (
	filter: filter,
	pre_filters: pre_filter[]
): Array<string> => {
	let state = [];
	pre_filters.forEach((pre_filter) => {
		if (pre_filter.filter_target == filter.filter_target) {
			state = [...state, ...pre_filter.filter_values];
		}
	});
	return state;
};
