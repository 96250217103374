"use client";
import React, { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { AllData, AnimatedPlayer } from "../../interfaces/blocks";

type animatedPlayerProps = AllData<AnimatedPlayer>;

// Dynamic import of lottie-web
const loadLottie = () => import("lottie-web");

const RenderAnimatedPlayerComponent = ({ data }: animatedPlayerProps) => {
	let { animation_file, animation_data } = data;
	const ref = useRef<HTMLDivElement>(null);
	const [lottie, setLottie] = useState<any>(null);

	
	useEffect(() => {
		loadLottie().then((Lottie) => setLottie(Lottie.default));
	}, []);
	
	useEffect(() => {
		if (lottie && ref.current) {
		  console.log("Initializing Lottie animation");
		  const animationConfig: any = {
			container: ref.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
		  };
		  if (animation_file) {
			animationConfig.path = animation_file;
		  } else if (animation_data) {
			animationConfig.animationData = animation_data;
		  }
		  const animation = lottie.loadAnimation(animationConfig);
	  
		  return () => animation.destroy();
		}
	  }, [lottie, animation_file, animation_data]);
	  

	return (
		<div
			id="animatedPlayer"
			className="h-full w-64 mb-8 md:mb-10 mt-1"
			ref={ref}
		/>
	);
};

// Next.js dynamic import for the component
export default dynamic(() => Promise.resolve(RenderAnimatedPlayerComponent), {
	ssr: false,
});
