"use client";

import React from "react";
import { ListItem, List } from "../list.interfaces";
import { Container } from "../../../styles/components";
import RenderMediaComponent from "../../Media";
import RenderTextBlock from "../../Text";

const RenderCheckList = ({ items, advanced, styling, mobileView }: List) => {
	const renderCheckList = (item: ListItem, index: number) => {
		return (
			<div key={index} className="flex gap-3">
				<div className={"mt-1"}>
					<RenderMediaComponent
						data={{
							media_src: item.media_src,
							styling: {
								media_sizing: {
									desktop: {
										height: "h-4",
										width: "w-auto",
									},
								},
								cover: false,
							},
						}}
						mobileView={mobileView}
					/>
				</div>
				<div className={"flex-1"}>
					<RenderTextBlock
						data={{ content: item.text, styling: { flex: false } }}
					/>
				</div>
			</div>
		);
	};
	return (
		// eslint-disable-next-line
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className="list flex flex-col gap-3 text-pretty"
		>
			{items.map((item: ListItem, index: number) => {
				return renderCheckList(item, index);
			})}
		</Container>
	);
};

export default RenderCheckList;
