"use client";
import AboutUs from "./partials/AboutUs";
import React from "react";
import { aboutUsType } from "./aboutUs.interface";
import RenderButton from "@/components/Button/partials/Button";

type aboutUsProps = {
	data: aboutUsType;
	mobileView: boolean;
};

const RenderAboutUs = ({ data, mobileView }: aboutUsProps) => {
	const {
		header,
		text1,
		text2,
		text3,
		text4,
		text5,
		bg_img,
		layer1_img,
		layer2_img,
		button,
	} = data;

	return (
		<main
			className={
				"!bg-[length:900px,1687px,auto] md:!bg-[position:center_top_134px,center_top_134px,center_top] !bg-[position:center_top_-36px,center_top_-36px,center_top_-170px]"
			}
			style={{
				background: `url("${layer2_img}") no-repeat, url("${layer1_img}") no-repeat, url("${bg_img}") repeat-x, #C0C458`,
			}}
		>
			<AboutUs
				Header={header}
				text1={text1}
				text2={text2}
				text3={text3}
				text4={text4}
				text5={text5}
			/>
			<div
				className={"fixed bottom-6 left-1/2 -translate-x-1/2 transform"}
			>
				<RenderButton button={button} index={0}></RenderButton>
			</div>
		</main>
	);
};

export default RenderAboutUs;
