import tw from "tailwind-styled-components";

type RowStylingProps = {
	$mobileView?: boolean;
	$styling?: {
		no_margin?: boolean;
		rounded_corners?: boolean;
		sizing?: string;
		full_width?: boolean;
		direction?: string;
		variant?: string;
		scroll?: boolean;
		not_padded?: boolean;
		no_width_constraint?: boolean;
		spacing?: string;
		padding?: string;
	};
};

type ColStylingProps = {
	$mobileView?: boolean;
	$styling?: {
		overlap?: boolean;
		variant?: string;
		vcenter?: boolean;
		hcenter?: boolean;
		padded?: boolean;
		padding?: string;
		flex_width?: boolean;
		sizing?: string;
	};
};

export const StyledRow = tw.div<RowStylingProps>`
    flex
    flex-col
    md:gap-28
    gap-6
    h-fit
    ${(variant) =>
		variant.$styling?.not_padded !== true &&
		variant.$styling?.sizing !== "sm" &&
		"md:py-20 py-12"}
    ${(variant) => variant.$styling?.scroll && "overflow-scroll"}
    ${(variant) =>
		variant.$styling?.full_width === false && "max-w-2xl mx-auto"}
    ${(variant) => variant.$styling?.sizing === "sm" && "min-h-[310px] pb-3"}
    ${(variant) => variant.$styling?.sizing === "md" && "min-h-[625px]"}
    ${(variant) => variant.$styling?.sizing === "lg" && "min-h-[720px]"}
    ${(variant) => variant.$styling?.sizing === "xl" && "min-h-[755px]"}
    ${(variant) => variant.$styling?.sizing === "2xl" && "min-h-[815px]"}
    ${(variant) => variant.$styling?.sizing === "vertcal90" && "min-h-[80vh]"}
    ${(variant) => variant.$styling?.no_margin !== true && "md:mx-24 mx-6"}
    ${(variant) => variant.$styling?.rounded_corners == true && "rounded"}
    ${(variant) =>
		variant.$styling?.direction === "overlap" && "flex-col-reverse"}
    ${(variant) =>
		variant.$styling?.direction?.includes("mobile-reversed")
			? "flex-col-reverse"
			: "flex-col"}
    ${(variant) =>
		variant.$styling?.variant === "columns" &&
		(variant.$styling?.direction?.includes("desktop-reversed")
			? "md:flex-row-reverse"
			: "md:flex-row")}
    ${(variant) =>
		variant.$styling?.variant === "rows" &&
		(variant.$styling?.direction?.includes("desktop-normal")
			? "md:flex-col-reverse"
			: "md:flex-col")}
    ${(variant) => variant.$styling?.spacing === "between" && "justify-between"}
    ${(variant) => variant.$styling?.no_width_constraint && "!gap-0"}
    ${(variant) =>
		variant.$styling?.padding?.includes("tight") && "py-8 md:py-12"}
    ${(variant) =>
		variant.$styling?.padding?.includes("tighter") && "py-6 md:py-10"}
    ${(variant) => variant.$styling?.padding === "base" && "py-12 md:py-16"}
    ${(variant) => variant.$styling?.padding === "loose" && "py-16 md:py-20"}
    ${(variant) => variant.$styling?.padding === "none" && "!py-0"} 
`;

export const StyledColumn = tw.div<ColStylingProps>`
    relative
    flex
    flex-col
    gap-6
    ${(variant) => !variant.$styling?.flex_width && "self-stretch"}
    ${(variant) =>
		variant.$styling?.variant === "card" &&
		"md:mx-24 mx-6 rounded md:pt-12 p-6 drop-shadow"}
    ${(variant) => variant.$styling?.variant === undefined && "w-full"}
    ${(variant) => variant.$styling?.variant === "full" && "w-full h-full"}
    ${(variant) => variant.$styling?.hcenter && "items-center [&>*]:m-auto"}
    ${(variant) => variant.$styling?.vcenter && "justify-center"}
    ${(variant) =>
		variant.$styling?.padded ? "md:px-24 px-6" : variant.$styling?.padding}
    ${(variant) => (variant.$styling?.sizing ? variant.$styling?.sizing : "")}
    ${(variant) =>
		variant.$styling?.overlap &&
		"bg-white z-20 self-center drop-shadow-none max-w-[20.5rem] px-6 md:max-w-[77rem] -mt-28 md:px-52"}
`;
