export const translations = {
  applyViaWhatsApp: {
    en: "Apply via WhatsApp",
    nl: "Solliciteer via WhatsApp",
    fr: "Postuler via WhatsApp",
    de: "Bewerben Sie sich über WhatsApp",
  },
  enterPhoneNumber: {
    en: "Enter your phone number",
    nl: "Voer je telefoonnummer in",
    fr: "Entrez votre numéro de téléphone",
    de: "Geben Sie Ihre Telefonnummer ein",
  },
  submit: {
    en: "Submit",
    nl: "Versturen",
    fr: "Envoyer",
    de: "Absenden",
  },
  submitting: {
    en: "Submitting...",
    nl: "Versturen...",
    fr: "Envoi...",
    de: "Senden...",
  },
  thanksForApplying: {
    en: "Thanks for applying!",
    nl: "Bedankt voor je sollicitatie!",
    fr: "Merci pour votre candidature !",
    de: "Danke für Ihre Bewerbung!",
  },
  contactSoon: {
    en: "We'll contact you soon via WhatsApp.",
    nl: "We contacteren je snel via WhatsApp.",
    fr: "On vous contactera bientôt via WhatsApp.",
    de: "Wir melden uns bald über WhatsApp.",
  },
};