"use client";

import React, { useState } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import { AllData } from "../../interfaces/blocks";
import { Calendar } from "./calendar.interfaces";
import MonthlyView from "./partials/Views/Monthly";
import WeeklyView from "./partials/Views/Weekly";
import YearlyView from "./partials/Views/Yearly";
import { addHours, parse } from "date-fns";
import { localeRouteInterface } from "../../utils";

type Props = {
	data: Calendar;
	mobileView: boolean;
	pageArgs: localeRouteInterface;
};

const RenderCalendarComponent = ({ data, mobileView, pageArgs }: Props) => {
	const [view, setView] = useState(data.type);
	let parsedEvents = data.events.map((event) => {
		return {
			...event,
			start: parse(
				event.start,
				"dd/MM/yyyy h:mm a",
				new Date()
			).toISOString(),
			end: addHours(
				parse(event.start, "dd/MM/yyyy h:mm a", new Date()),
				event.duration
			),
		};
	});
	// sort the events by their start times
	parsedEvents = parsedEvents.sort(function (a, b) {
		return new Date(a.start).getTime() - new Date(b.start).getTime();
	});
	const startDate = parsedEvents[0]
		? new Date(parsedEvents[0]?.start)
		: new Date();
	const [date, setDate] = useState(startDate);
	const dayLabels = {
		fr: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
		nl: ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"],
		de: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
		en: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
	};
	return (
		<div>
			{view === "monthly" && (
				<MonthlyView
					component={data}
					date={date}
					setDate={setDate}
					view={view}
					setView={setView}
					daysOfWeek={dayLabels[pageArgs?.locale?.language || "nl"]}
					events={parsedEvents}
				/>
			)}
			{view === "weekly" && (
				<WeeklyView
					component={data}
					date={date}
					setDate={setDate}
					view={view}
					setView={setView}
					daysOfWeek={dayLabels[pageArgs?.locale?.language || "nl"]}
					events={parsedEvents}
					mobileView={mobileView}
				/>
			)}
			{view === "yearly" && (
				<YearlyView component={data} events={parsedEvents} />
			)}
		</div>
	);
};

export default RenderCalendarComponent;
