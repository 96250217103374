import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { format, startOfWeek, addDays } from "date-fns";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Flicking, { FlickingEvents, MOVE_TYPE } from "@egjs/react-flicking";
import { htmlToReactParser } from "../../../../utils";

const dateFnsOptions: {
	locale?: Locale;
	weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
	firstWeekContainsDate?: number;
	useAdditionalWeekYearTokens?: boolean;
	useAdditionalDayOfYearTokens?: boolean;
} = { weekStartsOn: 1 };

const RenderWeeklyHeader = ({ component }) => {
	const renderHours = () => {
		const hours: number[] = [];

		for (
			let i: number = component.styling.weekly.start_hour;
			i <= component.styling.weekly.end_hour;
			i++
		) {
			hours.push(i);
		}

		return hours.map((hour: any) => (
			<div
				key={`hour-${hour}`}
				className={`${hour % 2 == 0 && "invisible"}`}
			>
				{hour.toLocaleString("en-US", {
					minimumIntegerDigits: 2,
					useGrouping: false,
				})}
			</div>
		));
	};

	const paddingTop =
		(component.styling.weekly.show_dates ? 20 : 0) +
		(component.styling.weekly.show_controls ? 75 : 0) +
		20 +
		"px";

	return (
		<div
			className="absolute"
			style={{
				height: "100%",
				paddingRight: "15px",
				paddingTop: paddingTop,
			}}
		>
			<div
				className="py-2 text-sm text-gray-700"
				style={{ height: "100%" }}
			>
				<div
					className="grid grid-cols-1 grid-flow-row auto-rows-auto"
					style={{ height: "400px" }}
				>
					{renderHours()}
				</div>
			</div>
		</div>
	);
};

const RenderWeeklyView = ({
	component,
	date,
	setDate,
	view,
	setView,
	daysOfWeek,
	events,
}) => {
	const weeklyHoursShown =
		component.styling.weekly.end_hour -
		component.styling.weekly.start_hour +
		1;
	daysOfWeek = component.styling?.weekly?.show_weekends
		? daysOfWeek
		: daysOfWeek.slice(0, 5);
	const prev = () => {
		setDate(addDays(date, -7));
	};

	const next = () => {
		setDate(addDays(date, 7));
	};

	const renderEvents = () => {
		return daysOfWeek.map((dayOfWeek, i) => {
			const day = addDays(startOfWeek(date, dateFnsOptions), 0 * 7 + i);
			const filteredEvents = events.filter((event) => {
				const startDateTime = new Date(event.start);
				return startDateTime.getDate() === day.getDate();
			});
			// Sort events by start time
			const sortedEvents = filteredEvents.sort((a, b) => {
				const startA = new Date(a.start).getTime();
				const startB = new Date(b.start).getTime();
				return startA - startB;
			});
			return (
				<div
					key={day.toISOString()}
					className="text-sm text-gray-700 panel"
					style={{ minHeight: "400px", height: "100%" }}
				>
					{component.styling.weekly.show_dates && (
						<Typography
							variant="subtitle2"
							align="center"
							className="font-medium"
						>
							{format(day, "MMM d", dateFnsOptions)}
						</Typography>
					)}
					<div
						className="relative h-full"
						style={{ height: "400px" }}
					>
						{sortedEvents.map((event, index) => {
							const startDateTime = new Date(event.start);
							const endDateTime = new Date(event.end);
							const startMinuteDecimal =
								startDateTime.getMinutes() / 60;
							const endMinuteDecimal =
								endDateTime.getMinutes() / 60;
							const dateTimeSpan =
								endDateTime.getHours() +
								endMinuteDecimal -
								(startDateTime.getHours() + startMinuteDecimal);
							const hourSpan = 400 / weeklyHoursShown;
							const yPosition =
								hourSpan *
								(startDateTime.getHours() -
									component.styling.weekly.start_hour +
									startMinuteDecimal);
							const eventHeight = hourSpan * dateTimeSpan - 8;
							let overlap = false;

							// Check for overlap with the previous event
							if (index > 0) {
								const prevEndDateTime = new Date(
									sortedEvents[index - 1].end
								);
								const prevStartDateTime = new Date(
									sortedEvents[index - 1].start
								);
								const prevEndMinuteDecimal =
									prevEndDateTime.getMinutes() / 60;
								const prevStartMinuteDecimal =
									prevStartDateTime.getMinutes() / 60;
								const prevEndPosition =
									hourSpan *
									(prevEndDateTime.getHours() -
										component.styling.weekly.start_hour +
										prevEndMinuteDecimal);

								// Adjust width if there is an overlap
								if (yPosition < prevEndPosition) {
									const overlapWidth =
										prevEndPosition - yPosition;
									overlap = true;
								}
							}

							return (
								<div
									key={event.start + event.title}
									className="rounded p-2 my-2 w-full break-all"
									style={{
										position: "absolute",
										top: `${yPosition}px`,
										height: `${eventHeight}px`,
										minHeight: "35px",
										width: overlap ? "80%" : "100%",
										right: overlap ? "0px" : "initial",
										backgroundColor: event.background_color,
										color: event.text_color,
									}}
								>
									{component.styling.weekly.show_times && (
										<Typography
											variant="body2"
											className="font-semibold"
										>
											{startDateTime.toLocaleTimeString(
												"en-US",
												{
													hour: "numeric",
													minute: "2-digit",
												}
											)}{" "}
											-{" "}
											{endDateTime.toLocaleTimeString(
												"en-US",
												{
													hour: "numeric",
													minute: "2-digit",
												}
											)}
										</Typography>
									)}
									{htmlToReactParser(event.text)}
								</div>
							);
						})}
					</div>
				</div>
			);
		});
	};
	const gridClasses = component.styling?.weekly?.show_weekends
		? "grid-cols-7"
		: "grid-cols-5";
	return (
		<div className="pl-6">
			{component.styling.weekly.show_controls && (
				<div className="flex items-center justify-between py-4">
					<Typography variant="body1" className="font-medium">
						{format(date, "MMMM yyyy", dateFnsOptions)}
					</Typography>
					<div>
						<button
							className="p-2 rounded-lg hover:bg-gray-100"
							onClick={prev}
						>
							<FontAwesomeIcon
								icon={faChevronLeft}
								className="h-6 w-6 text-gray-500"
							/>
						</button>
						<button
							className="p-2 rounded-lg hover:bg-gray-100 ml-2"
							onClick={next}
						>
							<FontAwesomeIcon
								icon={faChevronRight}
								className="h-6 w-6 text-gray-500"
							/>
						</button>
					</div>
				</div>
			)}
			<div className={`grid ${gridClasses} gap-4`}>
				{daysOfWeek.map((day) => (
					<div key={day} className="text-center">
						<Typography variant="body2" className="font-medium">
							{day}
						</Typography>
					</div>
				))}
			</div>
			<div className={`grid ${gridClasses} gap-2`}>{renderEvents()}</div>
		</div>
	);
};

export default function WeeklyView({
	component,
	date,
	setDate,
	view,
	setView,
	daysOfWeek,
	events,
	mobileView,
}) {
	const [slider, setSlider] = useState(null);
	const divStyles: CSSProperties = {
		minWidth: "712px",
		overflow: "hidden",
	};

	const Calendar = () => {
		return (
			<div style={divStyles} className="p-6 rounded bg-white">
				<RenderWeeklyHeader component={component} />
				<RenderWeeklyView
					component={component}
					date={date}
					setDate={setDate}
					view={view}
					setView={setView}
					daysOfWeek={daysOfWeek}
					events={events}
				/>
			</div>
		);
	};

	return (
		<div>
			{mobileView ? (
				<Flicking
					horizontal={true}
					align="prev"
					moveType={MOVE_TYPE.FREE_SCROLL} // Set to "snap"
					ref={(e) => setSlider(e)}
				>
					<div>
						<Calendar></Calendar>
					</div>
					<div style={{ width: "50px" }}></div>
				</Flicking>
			) : (
				<Calendar></Calendar>
			)}
		</div>
	);
}
