"use client";
import { Button, Divider, IconButton, ListItem } from "@mui/material";
import React, { useState } from "react";
import { filterState, Job, JobList } from "./joblist.interfaces";
import RenderJobTile from "./partials/JobTile";
import RenderFilters from "./partials/RenderFilter";
import RenderSearchBarComponent from "../SearchBar";
import { FilterAlt, Close, ChevronRight } from "@mui/icons-material";
import { createFilterCheckState } from "./partials/createFilterCheckState";
import { getFilteredJobs } from "./partials/getFilteredJobs";
import { localeRouteInterface } from "../../utils";

type props = {
	data: JobList;
	mobileView: boolean;
	pageArgs: localeRouteInterface;
	context: any;
};

const RenderJobListComponent = ({
	data,
	mobileView,
	pageArgs,
	context,
}: props) => {
	const { filters, pre_filters } = data;
	const jobList = context?.jobs?.data || [];
	const [showFilter, setShowFilters] = useState<boolean>(false);
	const [renderedJobsCount, setRenderedJobsCount] = useState<number>(10);
	const [filterSearchState, setSearchFilterState] = useState<string>(
		pageArgs.query.search || ""
	);
	const [filterCheckState, setFilterCheckState] = useState<filterState>(
		createFilterCheckState(filters ?? [], pre_filters ?? [])
	);
	const search_placeholder = {
		nl: "Zoeken...",
		fr: "Rechercher…",
		de: "Suchen...",
		en: "Search...",
	};
	const filteredJobList = getFilteredJobs(
		jobList,
		filterSearchState,
		filterCheckState
	);

	const RenderFilteredJobList = () => {
		const itemsToShow = filteredJobList.slice(0, renderedJobsCount);

		return (
			<>
				<h3 className="text-grey3 hidden md:block pb-4">
					{filteredJobList.length} Jobs
				</h3>
				<Divider className="hidden md:block" />
				{itemsToShow.map((job: Job, index: number) => (
					<div key={index}>
						<ListItem
							disablePadding
							alignItems="flex-start"
							className="md:py-8 py-6"
						>
							<RenderJobTile
								key={index}
								job={job}
								pageArgs={pageArgs}
							/>
							<ChevronRight className="m-auto w-[28px] h-[28px]" />
						</ListItem>
						<Divider />
					</div>
				))}
				{filteredJobList.length > 10 &&
					renderedJobsCount <= filteredJobList.length && (
						<div className="pt-8 flex w-full justify-center">
							<RenderShowMoreButton />
						</div>
					)}
			</>
		);
	};

	const RenderShowMoreButton = () => {
		return (
			<Button
				className="!rounded-full !py-3 !px-8 !bg-green1 !normal-case !w-fit !mx-auto text-base"
				variant="contained"
				disableElevation={true}
				onClick={(e) => setRenderedJobsCount(renderedJobsCount + 10)}
			>
				{data?.show_more_text
					? data?.show_more_text?.replace("{count}", "10") ||
					  `Show 10 more`
					: "Show 10 more"}
			</Button>
		);
	};

	const RenderMobileFilters = () => {
		return (
			<div className="flex flex-col gap-6 fixed inset-0 z-[101] bg-white p-6 overflow-scroll">
				<span className="w-full flex justify-between items-center">
					<h2>{data?.filter_title || "Filters"}</h2>
					<IconButton
						size="large"
						onClick={(e) => setShowFilters(false)}
					>
						<Close className="!text-3xl text-grey5" />
					</IconButton>
				</span>
				<RenderFilters
					filters={filters ?? []}
					setter={setFilterCheckState}
					state={filterCheckState}
					jobs={jobList}
				/>
				<Button
					variant="contained"
					className="!normal-case !px-4 !py-2.5 !rounded-full !bg-green1 w-fit"
					disableElevation={true}
					onClick={(e) => setShowFilters(false)}
				>
					{filteredJobList.length} Results
				</Button>
			</div>
		);
	};

	return (
		<div className="w-full mx-auto -mt-4 md:mt-0">
			<div className={"md:py-6"}>
				{mobileView && showFilter ? (
					RenderMobileFilters()
				) : (
					<div className="flex md:gap-10 flex-col md:flex-row">
						<div className="flex-col flex md:hidden">
							<p className=" text-grey3">
								{filteredJobList.length}{" "}
								{data?.job_term || "Jobs"}
							</p>
						</div>
						<div className="md:flex md:flex-col gap-4 md:w-1/4 sticky top-0 md:relative z-10 pt-6 pb-2 md:px-0 w-full md:p-0 bg-white">
							<h3 className="hidden md:block">
								<strong>
									{" "}
									{data?.filter_title || "Filters"}
								</strong>
							</h3>
							<div className="w-full md:w-48">
								<RenderSearchBarComponent
									data={{
										value: filterSearchState,
										placeholder:
											search_placeholder[
												pageArgs.locale.language
											],
										advanced: {
											enabled: true,
											state_setter: setSearchFilterState,
										},
										styling: {
											rounded_corners: mobileView,
											shadow: mobileView,
										},
									}}
								/>
							</div>
							{!mobileView && (
								<RenderFilters
									filters={filters ?? []}
									setter={setFilterCheckState}
									state={filterCheckState}
									jobs={jobList}
								/>
							)}
						</div>
						<div className="md:w-3/4 flex flex-col md:p-0">
							<RenderFilteredJobList />
						</div>
						<div className="sticky md:hidden flex bottom-0 bg-white py-4">
							<Button
								variant="contained"
								className="!normal-case !pl-4 !pr-8 !py-2.5 !rounded-full !bg-green1 !mx-auto text-base"
								disableElevation={true}
								onClick={(e) => setShowFilters(true)}
							>
								<span className="flex gap-3">
									<FilterAlt />{" "}
									{data?.filter_title || "Filters"}
								</span>
							</Button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default RenderJobListComponent;
