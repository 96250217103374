"use client";
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RenderButtonsComponent from "@/components/Button";
import Image from "next/image";
import { TextField } from "@mui/material";
import RenderButton from "@/components/Button/partials/Button";

type ShareModalProps = {
	data: any;
	mobileView: boolean;
};

const RenderShareModalComponent = ({ data, mobileView }: ShareModalProps) => {
	const [open, setOpen] = useState(false);
	const [copied, setCopied] = useState(false);

	const handleOpen = () => {
		if (!mobileView) {
			setOpen(true);
		} else {
			handleMobileShare();
		}
	};
	const handleClose = () => setOpen(false);

	const handleMobileShare = async () => {
		try {
			await navigator.share({
				title: `Picnic - ${data.title}`,
				text: data.link,
				url: "https://example.com", // You can replace this with your actual URL
			});
		} catch (error) {
			console.error("Error sharing:", error);
			// Handle error if sharing fails
		}
	};
	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(data.link); // Step 3: Copy text input value to clipboard
		setCopied(true);
	};
	return (
		<>
			<RenderButton
				button={{
					text: mobileView ? undefined : "Share",
					target: "_self",
					icon: "https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/share-icon_1711705033.png",
					onClick: handleOpen,
					styling: {
						sizing: mobileView ? "xxsm" : "sm",
						background_color: "white",
						rounded: true,
						circle: true,
						border_color: "grey2",
						text_color: "grey3",
						padded: true,
						icon_size: 15,
					},
				}}
				index={2}
			/>
			<Modal
				keepMounted
				open={open}
				onClose={handleClose}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[50%] bg-white rounded-3xl shadow-lg p-8">
					<IconButton
						style={{
							position: "absolute",
							top: "8px",
							right: "8px",
						}}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
					<div className="text-center w-full flex flex-col justify-center">
						<div className="flex justify-center">
							<Image
								src={
									"https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/blog-share-picnic_1711712668.png"
								}
								alt="share-picnic"
								width={162}
								height={146}
							></Image>
						</div>
						<div className="flex py-2 justify-center">
							<h3>
								<b>Share this with...</b>
							</h3>
						</div>
						<div className="flex justify-center gap-8 space-x-4 py-2">
							<a
								className="w-12 h-12 border border-solid border-grey2 bg-white rounded-full flex items-center justify-center text-white"
								href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
									data.link
								)}`}
								target="_blank"
							>
								<Image
									src={
										"https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/linkedin_1711713589.png"
									}
									alt="LinkedIn"
									width={24}
									height={24}
								/>
							</a>
							<a
								className="w-12 h-12 border border-solid border-grey2 bg-white rounded-full flex items-center justify-center text-white"
								href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
									data.link
								)}`}
								target="_blank"
							>
								<Image
									src={
										"https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/facebook_1711713574.png"
									}
									alt="Facebook"
									width={28}
									height={28}
								/>
							</a>
							<a
								className="w-12 h-12 border border-solid border-grey2 bg-white rounded-full flex items-center justify-center text-white"
								href={`https://twitter.com/intent/tweet?url=${encodeURI(
									data.link
								)}`}
								target="_blank"
							>
								<Image
									src={
										"https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/twitter_1711713558.png"
									}
									alt="Twitter"
									width={24}
									height={24}
								/>
							</a>
						</div>
						<div className="flex justify-center pt-8">
							<TextField
								className={`bg-white w-full rounded-full h-12`}
								value={data.link}
								InputProps={{
									endAdornment: (
										<div>
											<RenderButtonsComponent
												data={{
													buttons: [
														{
															text: copied
																? "✓"
																: "Copy",
															onClick:
																handleCopyToClipboard,
															styling: {
																sizing: "free",
																background_color:
																	"green1",
																rounded: true,
																text_color:
																	"white",
															},
														},
													],
												}}
											/>
										</div>
									),
								}}
								sx={{
									fieldset: {
										borderRadius: "9999px",
										boxShadow: "none",
										padding: "12px",
										maxWidth: "650px",
									},
									maxWidth: "650px",
									"& .MuiInputBase-root": {
										height: "100%", // Adjust this value as needed
										paddingLeft: "0px",
										paddingRight: "8px",
									},
									"& .MuiInputBase-input": {
										paddingRight: "25px",
										textOverflow: "ellipsis",
									},
								}}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default RenderShareModalComponent;
