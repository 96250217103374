import React from "react";

type SizingRecord = Record<string, string>;
type MediaSizing = Record<string, number>;
type MediaSizingRecord = Record<string, MediaSizing>;

export const horizontalAlignmentMap: SizingRecord = {
	left: "flex-start",
	center: "center",
	right: "flex-end",
};

export const verticalAlignmentMap: SizingRecord = {
	top: "flex-start",
	middle: "center",
	bottom: "flex-end",
};

const ImageSizingMap: MediaSizing = {
	sm: 150,
	md: 350,
	lg: 450,
	xl: 600,
};

const videoSizingMap: MediaSizing = {
	sm: 150,
	md: 300,
	lg: 450,
	xl: 600,
};

export const MediaSizingMap: MediaSizingRecord = {
	image: ImageSizingMap,
	video: videoSizingMap,
};

export const getTextAlignmentCSS = (
	h_align: string,
	v_align: string
): React.CSSProperties => {
	const CSS: React.CSSProperties = {
		justifyContent: horizontalAlignmentMap[h_align],
		alignItems: verticalAlignmentMap[v_align],
	};
	return CSS;
};

export const getMediaCSS = (type: string, size: string) => {
	const dimensions: number = MediaSizingMap[type][size];

	const CSS: React.CSSProperties = {
		width: dimensions,
		height: "auto",
	};
	return CSS;
};
