"use client";

import React from "react";
import { Grid as GridInterface } from "./grid.interfaces";
import { Grid } from "@mui/material";
import RenderCardComponent from "../Card";
import RenderAccordionComponent from "../Accordion";
import { computeRowHeight } from "../../utils";
import RenderMediaComponent from "../Media";

type props = {
	data: GridInterface;
	mobileView: boolean;
};

const RenderGridItem = ({
	element,
	rowSizing,
	mobileView,
}: {
	element: any;
	rowSizing: string;
	mobileView: boolean;
}) => {
	if (rowSizing != "auto") {
		element.data.styling.sizing = "full";
	}
	if (element.type == "card") {
		return (
			<RenderCardComponent data={element.data} mobileView={mobileView} />
		);
	} else if (element.type == "accordion") {
		return <RenderAccordionComponent data={element.data} />;
	} else if (element.type == "media") {
		return (
			<RenderMediaComponent data={element.data} mobileView={mobileView} />
		);
	}
	return null;
};

const RenderGridComponent = ({ data, mobileView }: props) => {
	const { items, styling } = data;
	const masonryView = mobileView && data.styling.variant == "masonry";
	const itemsPerRow = styling?.itemsPerRow || { xs: 2, sm: 2, md: 4, lg: 4 };
	const rowSizing =
		(mobileView
			? styling?.rowSizing?.mobile
			: styling?.rowSizing?.desktop) || "auto";
	const rowHeight = computeRowHeight(mobileView, rowSizing);

	return (
		<Grid container spacing={styling?.spacing ?? 2}>
			{items.map((element, index) => (
				<Grid
					item
					xs={masonryView && index == 0 ? 12 : 12 / itemsPerRow["xs"]}
					md={masonryView && index == 0 ? 12 : 12 / itemsPerRow["md"]}
					style={{ height: rowHeight }}
					key={index}
				>
					<RenderGridItem
						element={element}
						rowSizing={rowSizing}
						mobileView={mobileView}
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default RenderGridComponent;
