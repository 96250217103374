"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
import { Footer, FooterLinks } from "./footer.interfaces";
import RenderSocialButtonsComponent from "./partials/SocialButton";
import { Divider, Grid } from "@mui/material";
import RenderTextBlock from "../Text";

type footerProps = {
	footer: Footer;
};

const RenderFooterComponent = ({ footer }: footerProps) => {
	const {
		media_src,
		columns,
		advanced,
		styling,
		socials,
		cookie_privacy,
		mainTextDesktop,
		mainTextMobile,
	} = footer;
	const router = usePathname();
	const locale = "nl";
	const parsed_footer_cols = (columns || []).reduce((groups, item) => {
		const group = groups[item.title] || [];
		group.push(item);
		groups[item.title] = group;
		return groups;
	}, {});

	const RenderCookiesPrivacy = (cookieprivacy: FooterLinks[]) => {
		return (
			<div className="my-2 flex w-full flex-col md:flex-row md:items-center items-start gap-2 md:gap-4 text-center md:justify-center">
				{cookieprivacy && cookieprivacy[0] && (
					<Link key={0} href={cookieprivacy[0].href} prefetch={false}>
						<p>{cookieprivacy[0].label}</p>
					</Link>
				)}
				<p className="ot-sdk-show-settings !m-0 cursor-pointer !capitalize">
					Cookie preferences
				</p>
				{cookieprivacy &&
					cookieprivacy.map((element: FooterLinks, index: number) => {
						if (index > 0) {
							return (
								<Link
									key={index}
									href={element.href}
									prefetch={false}
								>
									<p>{element.label}</p>
								</Link>
							);
						} else {
							return null;
						}
					})}
			</div>
		);
	};

	const brandingTextExists = mainTextDesktop && mainTextDesktop != "";

	return (
		<footer className="footer relative mt-5 block bg-grey0 px-6 md:px-24 md:py-5">
			<Grid
				container
				className="!mx-auto max-w-[1440px] justify-between"
				spacing={2}
			>
				<Grid item xs={2} md={12}>
					<Link
						href={`/${locale}`}
						prefetch={false}
						className={"shake"}
					>
						<Image
							className="my-5 rounded"
							src={
								"https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/logo1_1694434608.svg"
							}
							alt={"logo"}
							width={48}
							height={48}
							loading="lazy"
						/>
					</Link>
				</Grid>
				{brandingTextExists && (
					<Grid item xs={10} md={4}>
						<>
							<div className="hidden h-full w-full items-center justify-start md:flex md:items-start font-bold">
								<RenderTextBlock
									data={{ content: mainTextDesktop }}
								/>
							</div>
							<div className="flex h-full w-full items-center justify-start md:hidden font-bold">
								<RenderTextBlock
									data={{ content: mainTextMobile }}
								/>
							</div>
						</>
					</Grid>
				)}
				<Grid item xs={12} md={brandingTextExists ? 6 : null}>
					<div
						className={`flex h-full w-full flex-col flex-wrap gap-6 md:gap-8 pt-4 md:flex-row md:gap-4 ${
							brandingTextExists ? "md:justify-end" : ""
						} md:pt-0 justify-between`}
					>
						{Object.keys(parsed_footer_cols).map(
							(col, col_index) => {
								return (
									<div
										className="flex w-full flex-col gap-2 md:gap-3 md:w-48"
										key={col_index}
									>
										<strong>{col}</strong>
										{parsed_footer_cols[col].map(
											(link, link_index) => {
												return (
													<Link
														href={
															link.type == "page"
																? `${locale}/${link.href}`
																: link.href
														}
														key={link_index}
													>
														<p>{link.label}</p>
													</Link>
												);
											}
										)}
									</div>
								);
							}
						)}
					</div>
				</Grid>
				<Divider className="w-full pt-8 md:pt-16" />
				<Grid item xs={12} padding={0} style={{ paddingTop: 0 }}>
					<div className="flex w-full flex-col gap-6 pl-0 md:pl-4 pt-6 text-grey4 md:flex-row md:justify-between md:pl-0">
						<RenderSocialButtonsComponent socials={socials} />
						{RenderCookiesPrivacy(cookie_privacy)}
						<div className="my-2 flex w-full items-center md:justify-end">
							<p>© {new Date().getFullYear()} Picnic</p>
						</div>
					</div>
				</Grid>
			</Grid>
		</footer>
	);
};

export default RenderFooterComponent;
