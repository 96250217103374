import { headerLink, Header } from "../header.interfaces";
import { SearchBar } from "../../SearchBar/searchbar.interfaces";
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	KeyboardArrowDown,
} from "@mui/icons-material";
import RenderLanguageSwitcherComponent from "../../LanguageSwitcher";
import React, { useState, useRef, forwardRef, Ref } from "react";
import { LanguageSwitcher } from "../../LanguageSwitcher/LanguageSwitcher.interfaces";
import RenderSearchBarComponent from "../../SearchBar";
import RenderButtonsComponent from "../../../components/Button";
import { localeRouteInterface } from "../../../utils";
import { buttonData } from "../../Button/buttons.interfaces";
import { buildUrl, isFullUrl } from "../../../utils/buildUrl";
import Link from "next/link";

interface navProps {
	mobileView: boolean;
	links: headerLink[];
	locales?: LanguageSwitcher;
	pageArgs: localeRouteInterface;
	className?: string;
	searchBar?: SearchBar;
	exitMenuButton?: string;
	button?: Header["button"];
	integrated?: boolean;
	textShadow?: boolean;
	isConsumer: boolean;
	enableLanguageSwitcher?: boolean;
	translations?: Record<string, boolean>;
}

export const Nav = forwardRef(
	(
		{
			mobileView,
			links,
			locales,
			pageArgs,
			className,
			searchBar,
			exitMenuButton,
			integrated,
			textShadow,
			isConsumer,
			enableLanguageSwitcher = true,
			translations,
		}: navProps,
		ref: React.MutableRefObject<HTMLDivElement>
	) => {
		if (links) {
			const linkElements = links.filter(
				(link) => link.element === "link" || link.element == undefined
			);
			const buttonElements = links.filter(
				(link) => link.element === "button"
			);
			return (
				<NavContainer
					className={`h-fit min-h-screen md:relative md:h-auto md:min-h-0 md:w-auto md:translate-x-0 md:flex-row md:items-center md:p-0 ${className}`}
					ref={ref}
					isConsumer={isConsumer}
					mobileView={mobileView}
				>
					{exitMenuButton && typeof ref !== "undefined" && (
						<ExitSubmenuButton text={exitMenuButton} _ref={ref} />
					)}
					{searchBar && mobileView && (
						<RenderSearchBarComponent data={searchBar} />
					)}
					{exitMenuButton && mobileView ? (
						<div className="h-full overflow-y-auto">
							{linkElements?.map((link, index) => (
								<NavLink
									key={index}
									link={link}
									index={index}
									integrated={integrated}
									pageArgs={pageArgs}
									mobileView={mobileView}
									submenu={false}
									textShadow={textShadow}
									isConsumer={isConsumer}
								/>
							))}
						</div>
					) : (
						linkElements?.map((link, index) => (
							<NavLink
								key={index}
								link={link}
								index={index}
								integrated={integrated}
								pageArgs={pageArgs}
								mobileView={mobileView}
								submenu={false}
								textShadow={textShadow}
								isConsumer={isConsumer}
							/>
						))
					)}

					{locales && enableLanguageSwitcher && (
						<RenderLanguageSwitcherComponent
							texts={locales.texts}
							countries={locales.countries}
							textShadow={textShadow}
							pageArgs={pageArgs}
							translations={translations}
						/>
					)}

					{buttonElements.length > 0 && (
						<RenderButtonsComponent
							className={"mx-auto mt-auto text-white"}
							data={{
								buttons: buttonElements.map(
									(button) =>
										({
											text: button.label,
											link: button.link,
											target: button.method,
											styling: {
												background_color: "red1",
												rounded: true,
												sizing: "full",
											},
										} as buttonData)
								),
							}}
						/>
					)}
				</NavContainer>
			);
		}
	}
);

interface navLinkProps {
	mobileView: boolean;
	link: headerLink;
	index: number;
	submenu: boolean;
	pageArgs: localeRouteInterface;
	integrated?: boolean;
	textShadow?: boolean;
	isConsumer: boolean;
}

const NavLink = ({
	mobileView,
	link,
	index,
	submenu,
	pageArgs,
	integrated,
	textShadow,
	isConsumer,
}: navLinkProps): JSX.Element => {
	const [isSubmenuActive, setIsSubmenuActive] = useState<boolean>(false);
	const submenuRef = useRef(null);

	const enableSubmenu = async (e: React.MouseEvent): Promise<void> => {
		e.preventDefault();
		await setIsSubmenuActive(true);
		if (mobileView) {
			setTimeout(() => {
				submenuSlideIn(submenuRef.current);
			}, 10);
		}
	};
	const href = buildUrl(link.link, pageArgs);
	const activeLink =
		!isFullUrl(href?.startsWith("/") ? href.substring(1) : href) &&
		pageArgs.route.slice(-1)[0] === href.split("/").slice(-1)[0];
	return (
		<div key={index} className={`${submenu && "text-grey5"}`}>
			<Link
				href={href}
				prefetch={true}
				onClick={mobileView && link.links && enableSubmenu}
				onMouseEnter={!mobileView && enableSubmenu}
				className={`${
					submenu && "hover:bg-grey1"
				} flex items-center gap-4 ${isConsumer ? "pt-2" : "py-2"} ${
					integrated && textShadow && "text-shadow shadow-black"
				} ${activeLink && "text-red1"}`}
			>
				<p
					className={`flex w-full items-center justify-between ${
						!isConsumer && "text-[1.3rem] font-normal md:text-base"
					} ${!submenu && !isConsumer && "md:font-semibold"}`}
				>
					<span className={`${link.links && "pr-2"}`}>
						{link.label}
					</span>
					{link.links &&
						(!mobileView && !submenu ? (
							<KeyboardArrowDown
								className={
									"!transition [div:hover>a>p>&]:-scale-y-100"
								}
							/>
						) : (
							<KeyboardArrowRight className={"!text-[1.75rem]"} />
						))}
				</p>
			</Link>
			{link.links && isSubmenuActive && (
				<NavSubLinks
					mobileView={mobileView}
					_link={link}
					submenu={!submenu}
					ref={submenuRef}
					pageArgs={pageArgs}
					isConsumer={isConsumer}
				/>
			)}
		</div>
	);
};

export const submenuSlideIn = (submenu): void => {
	submenu.classList.toggle("-translate-x-full");
};

interface navSubLinksProps {
	mobileView: boolean;
	_link: headerLink;
	submenu: boolean;
	pageArgs: localeRouteInterface;
	isConsumer: boolean;
}

const NavSubLinks = forwardRef(
	(
		{ mobileView, _link, submenu, pageArgs, isConsumer }: navSubLinksProps,
		ref: Ref<HTMLDivElement>
	) => {
		const _links: headerLink[] = _link["links"];

		if (!mobileView) {
			return (
				<div
					className={`${
						submenu ? "pt-7" : "-mt-8 ml-[100%] pl-3"
					} absolute hidden font-normal [div:hover>&]:block`}
				>
					<div className="flex w-full flex-col rounded bg-white py-4 shadow [&_a]:px-4">
						{Object.values(_links)?.map((_link, index) => (
							<NavLink
								key={index}
								link={_link}
								index={index}
								mobileView={mobileView}
								submenu={true}
								pageArgs={pageArgs}
								isConsumer={isConsumer}
							/>
						))}
					</div>
				</div>
			);
		} else {
			return (
				<Nav
					links={_links}
					mobileView={true}
					exitMenuButton={_link["label"]}
					ref={ref}
					pageArgs={pageArgs}
					isConsumer={isConsumer}
				/>
			);
		}
	}
);

interface exitSubmenuBtnProps {
	text?: string;
	_ref: React.MutableRefObject<HTMLDivElement>;
}

export const ExitSubmenuButton = ({
	text,
	_ref,
}: exitSubmenuBtnProps): JSX.Element => {
	const disableSubmenu = (): void => {
		const submenu = _ref.current;
		submenu.classList.toggle("-translate-x-full");
	};

	return (
		<div
			className={"-mb-7 border-b border-grey1 pb-5 text-grey3"}
			onClick={disableSubmenu}
		>
			<KeyboardArrowLeft />
			<span className={"text-base font-normal"}>{text}</span>
		</div>
	);
};

interface navContainerProps {
	className?: string;
	children: React.ReactNode;
	isConsumer: boolean;
	mobileView?: boolean;
}

export const NavContainer = forwardRef(
	(
		{ className, children, isConsumer, mobileView }: navContainerProps,
		ref: Ref<HTMLDivElement>
	) => {
		return mobileView ? (
			<div
				className={`absolute inset-0 z-[52] flex -translate-x-full flex-col overflow-y-hidden ${
					isConsumer
						? "md:ml-4 justify-start gap-6"
						: "justify-start gap-10"
				} bg-white px-6 py-4 duration-300 [&>div:first-child]:mt-20 md:[&>div:first-child]:mt-0 ${className}`}
				ref={ref}
				style={{ maxHeight: "calc(100vh - 4rem)" }} // Adjust as necessary
			>
				{children}
			</div>
		) : (
			<div
				className={`absolute inset-0 z-[52] flex -translate-x-full flex-col ${
					isConsumer
						? "md:ml-4 justify-start gap-6"
						: "justify-start gap-10"
				} bg-white px-6 py-4 duration-300 [&>div:first-child]:mt-20 md:[&>div:first-child]:mt-0 ${className}`}
				ref={ref}
			>
				{children}
			</div>
		);
	}
);
