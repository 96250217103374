"use client";
import React, { useState } from "react";
import Image from "next/image";
import { mediaParseLogic } from "../../../utils/mediaParseLogic";
import { MediaProps } from "../media.interfaces";
import { HandleSizing } from "./HandleSizing";

export const RenderImage = ({ data, mobileView }: MediaProps) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const { media_src, styling, style } = data;
	var sizingClasses = HandleSizing(styling?.media_sizing, mobileView);
	const mediaClass = `${sizingClasses.width} ${sizingClasses.height} ${
		styling?.variant === "padded" || styling?.rounded_corners
			? styling?.rounded_size ?? "rounded"
			: ""
	}`;
	const currentMedia = mediaParseLogic(mobileView, media_src);
	let imageName = currentMedia?.src.split("/");
	imageName = imageName.pop().split(".")[0];
	imageName = imageName.replaceAll("-", " ").replaceAll("_", " ");

	const sizeByHeight = styling?.sizeByHeight || false;

	return (
		<>
			<div
				className="w-full"
				style={{
					...style,
					...(currentMedia?.meta
						? {
								display: !isLoaded ? "initial" : "none",
								opacity: !isLoaded ? 1 : 0,
								transition: "opacity 0.5s ease",
						  }
						: { display: "none" }),
					...(sizeByHeight ? { height: "100%" } : {}),
				}}
			>
				<Image
					src={
						currentMedia?.meta
							? currentMedia?.meta.blurhash
							: "data:image/webp;base64,UklGRjAAAABXRUJQVlA4ICQAAACQAQCdASoEAAIAAgA0JZwAAudYugAA/vShPTMGsRJli3i4AAA="
					}
					alt={currentMedia?.type}
					width={0}
					height={0}
					sizes={sizeByHeight ? "100vh" : "100vw"}
					className={`${mediaClass} ${
						(styling?.cover == undefined ||
							styling?.cover == true) &&
						"object-cover"
					}`}
					loading="lazy"
					quality={80}
					style={sizeByHeight ? { height: "100%" } : {}}
				/>
			</div>
			<Image
				src={currentMedia?.src}
				priority={
					styling?.priority != undefined ? styling.priority : false
				}
				alt={currentMedia?.meta?.alt ?? imageName}
				width={0}
				height={0}
				sizes={sizeByHeight ? "100vh" : "100vw"}
				onLoad={(e) => setIsLoaded(true)}
				className={`${mediaClass} ${
					(styling?.cover == undefined || styling?.cover == true) &&
					"object-cover"
				}`}
				style={{
					...style,
					...(currentMedia?.meta
						? {
								opacity: isLoaded ? 1 : 0,
								transition: "opacity 0.5s ease",
								position: isLoaded ? "initial" : "absolute",
						  }
						: {}),
					...(sizeByHeight ? { height: "100%" } : {}),
				}}
				loading={styling?.priority ? "eager" : "lazy"}
			/>
		</>
	);
};
