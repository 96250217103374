export const map_view_text = {
	nl: "Lijst bekijken",
	en: "View list",
	de: "Liste anzeigen",
	fr: "Afficher la liste",
};
export const list_view_text = {
	nl: "Kaart bekijken",
	en: "View map",
	de: "Ansichts Karte",
	fr: "Voir la carte",
};
export const apply_text = {
	nl: "Aanmelden",
	en: "Apply",
	de: "Anwenden",
	fr: "Appliquer",
};

// TODO substitute with actual disabled text
export const disabled_text = {
	nl: "Aanmelden",
	en: "Apply directly",
	de: "Anwenden",
	fr: "Appliquer",
};

export const search_text = {
	nl: "Zoeken...",
	en: "Search...",
	de: "Suchen...",
	fr: "Rechercher...",
};
