import tw from "tailwind-styled-components";

type cardProps = {
	$vcenter?: boolean;
	$hcenter?: boolean;
	$no_shadow?: boolean;
};

export const Card = tw.div<cardProps>`
    flex
    flex-col
    rounded
    ${(variant) => variant.$vcenter === true && "items-center [&>*]:m-auto"}
    ${(variant) => variant.$hcenter === true && "justify-center"}
    ${(variant) => (variant.$no_shadow === true ? "" : "drop-shadow")}
`;
