"use client";

import React from "react";
import {
	LocationOn,
	AccessTime,
	Person,
	CalendarToday,
} from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";

type IconnedContentProps = {
	content: Array<{
		icon: string;
		content: string;
		color?: string;
	}>;
};

const IconMaps: Record<string, React.ReactElement<SvgIconProps>> = {
	location: <LocationOn fontSize="small" />,
	clock: <AccessTime fontSize="small" />,
	user: <Person fontSize="small" />,
	date: <CalendarToday fontSize="small" />,
};

const RenderIconContent = ({ content }: IconnedContentProps) => {
	return (
		<div className="flex gap-3 ">
			{(content || []).map((content, index) => {
				if (content?.content) {
					return (
						<span
							key={index}
							className={`flex gap-1 text-sm text-${
								content.color ?? "grey3"
							}`}
						>
							{IconMaps[content.icon]}
							{content.content}
						</span>
					);
				}
			})}
		</div>
	);
};

export default RenderIconContent;
