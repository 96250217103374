import { job_location, nestedFilterArray } from "../joblist.interfaces";

export const getNestedLocationList = (
	location: job_location[]
): nestedFilterArray => {
	const countries = new Map();

	// Count occurrences of each country, state, and city
	location.forEach((loc) => {
		const { country, state, city } = loc;

		// skip if location is empty
		if (country == "" || state == "" || city == "") {
			return;
		}

		if (!countries.has(country)) {
			countries.set(country, {
				original_key: "country",
				children: new Map(),
			});
		}

		if (!countries.get(country).children.has(state)) {
			countries.get(country).children.set(state, {
				original_key: "state",
				children: new Map(),
			});
		}

		// Check if city name is different from the state name
		if (city !== state) {
			if (
				!countries.get(country).children.get(state).children.has(city)
			) {
				countries
					.get(country)
					.children.get(state)
					.children.set(city, { original_key: "city" });
			}
		}
	});

	// Create the final nested structure with original_key and remove duplicates
	const nestedStructure = [...countries].map(([country, countryObj]) => ({
		name: country,
		original_key: countryObj.original_key,
		children: [...countryObj.children].map(([state, stateObj]) => ({
			name: state,
			original_key: stateObj.original_key,
			children: [...stateObj.children].map(([city, cityObj]) => ({
				name: city,
				original_key: cityObj.original_key,
			})),
		})),
	}));

	return nestedStructure;
};
