"use client";
import { Divider, List, ListItem, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import RenderJobTile from "../JobList/partials/JobTile";
import RenderButtonsComponent from "../Button";
import { Buttons } from "../Button/buttons.interfaces";
import { SearchBar } from "./searchbar.interfaces";
import { Job } from "../JobList/joblist.interfaces";
import "./searchbar.styles.css";

type searchBarProps = {
	data: SearchBar;
	context?: any;
};

const RenderSearchBarComponent = ({ data, context }: searchBarProps) => {
	const { advanced, styling, placeholder } = data;
	const [searchterm, setSearchTerm] = useState(data.value || "");
	const [showSuggestions, setShowSuggestions] = useState(false);

	// show searchbar when enabled
	if (!advanced?.enabled) {
		return null;
	}

	const submitSearch = () => {
		window.location.href = `/jobs?search=${searchterm}`;
	};

	const handleSearch = (event) => {
		if (event.key === "Enter") {
			submitSearch();
		} else {
			setSearchTerm(event.target.value);
			if (advanced.state_setter) {
				advanced.state_setter(event.target.value);
			}
		}
	};

	const handleSearchBlur = () => {
		// Delay the blur action to check if the click is on the suggestions
		setTimeout(() => {
			if (
				!document.activeElement?.classList.contains("suggestion-item")
			) {
				setShowSuggestions(false);
			}
		}, 500);
	};

	const handleSearchFocus = () => {
		setShowSuggestions(true);
	};

	const RenderSuggestions = () => {
		const suggestionList = (context?.jobs?.data || [])
			.filter((job) =>
				(job?.data?.search_string || "")
					.toLowerCase()
					.includes(searchterm.toLowerCase())
			)
			.slice(0, 3);
		return (
			<List
				className="w-full md:max-w-[600px] p-0 bg-white z-50"
				sx={{
					borderRadius: "22px",
					boxShadow: "0px 4px 11px 0px rgba(0, 0, 0, 0.17)",
				}}
			>
				{suggestionList.length > 0 ? (
					suggestionList.map((suggestion: Job, index: number) => (
						<>
							<ListItem
								disablePadding
								key={index}
								alignItems="flex-start"
								className="md:p-4 p-3"
							>
								<RenderJobTile
									job={suggestion}
									variant="search_suggestion"
								/>
							</ListItem>
							{index < suggestionList.length - 1 && (
								<Divider variant="middle" component="li" />
							)}
						</>
					))
				) : (
					<>
						<ListItem alignItems="flex-start">
							<p>No Results</p>
						</ListItem>
					</>
				)}
			</List>
		);
	};

	const searchButtonData: Buttons = {
		buttons: [
			{
				text: "<b>Search</b>",
				onClick: submitSearch,
				styling: {
					sizing: "free",
					background_color: "green1",
					rounded: true,
					text_color: "white",
				},
			},
		],
	};

	return (
		<div
			className={`w-full md:max-w-[610px] flex flex-col gap-2 max-h-12 mt-2 md:mt-0 searchbar-container`}
		>
			<TextField
				className={`bg-white w-full rounded-full h-12 ${
					styling?.rounded_corners ? "rounded-full" : "rounded-md"
				}`}
				value={searchterm}
				placeholder={placeholder}
				autoComplete={"off"}
				onChange={handleSearch}
				onBlur={handleSearchBlur}
				onFocus={handleSearchFocus}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						submitSearch();
					}
				}}
				InputProps={{
					startAdornment: (
						<SearchIcon className="text-grey3 ml-3 mr-3" />
					),
					endAdornment: advanced?.show_button && (
						<div
							className={`endAdornment-active py-auto ${
								searchterm ? "visible" : "hidden"
							}`}
						>
							<RenderButtonsComponent data={searchButtonData} />
						</div>
					),
				}}
				sx={{
					fieldset: {
						borderRadius: styling?.rounded_corners
							? "9999px"
							: "8px",
						boxShadow: styling?.shadow
							? "0px 2px 8px 0px rgba(0, 0, 0, 0.12)"
							: "none",
						padding: "12px",
						maxWidth: "610px",
					},
					maxWidth: "610px",
					"&:focus": {
						"& .endAdornment-active": {
							display: "block", // Show the endAdornment on focus
						},
					},
					"& .MuiInputBase-root": {
						height: "100%", // Adjust this value as needed
						paddingLeft: "0px",
						paddingRight: "8px",
					},
				}}
			/>
			{advanced.show_suggestions &&
				showSuggestions &&
				searchterm !== "" && <RenderSuggestions />}
		</div>
	);
};

export default RenderSearchBarComponent;
