import tw from "tailwind-styled-components";

const StyledText = tw.div<{
	$alignment: string;
	$gap?: string;
	$justify: string;
	$width_restriction?: boolean;
	$flex?: boolean;
}>`
    ${(variant) => variant.$flex && "flex flex-col"}
    ${(variant) => variant.$alignment === "left" && "text-left"}
    ${(variant) => variant.$alignment === "right" && "text-right"}
    ${(variant) => variant.$alignment === "center" && "text-center"}
    ${(variant) => variant.$width_restriction && "md:w-[80%]"}
    ${(variant) => (variant.$gap ? `gap-${variant.$gap}` : "gap-2")}
    ${(variant) =>
		variant.$justify === "center" ? "justify-center" : "justify-between"}
`;

export default StyledText;
