"use client";
import { useEffect, useState } from "react";

const RenderDeeplinkRedirect = ({ data, mobileView, context }: any) => {
    useEffect(() => {
        window.location.href = data.deeplink;
    }, [data]);
    return null;
}

export default RenderDeeplinkRedirect;
