import { JOB_TERMS } from "../config/environment";
import { localeRouteInterface } from "./MarketandLanguage";
import { buildUrl } from "./buildUrl";

export const getUrl = (
	job,
	pageArgs: localeRouteInterface | null = null,
	skipLocale: boolean = false,
	term: string | null = null
) => {
	// replace all spaces in the job name with dashes
	const job_name = job?.name.replaceAll(/\s+/g, "-");
	// remove all spaces and join the teams with dashesR
	const job_teams = job.data?.teams
		.replaceAll(/\s+/g, "/")
		.replaceAll(",", "-");
	// concatonate everything
	let search_array: string[] = [
		job_teams,
		job_name,
		job.data?.location?.city,
		job.data?.location?.state,
		job.data?.location?.country,
	];
	let search_string = search_array
		.filter(function (element) {
			return element != "";
		})
		.join(" ");

	// replace special characters to be SEO and URL safe
	search_string = search_string.toLowerCase();
	search_string = search_string.replaceAll(/[()\\/]/g, "");
	search_string = search_string.replaceAll(/\s+/g, "/");
	search_string = search_string.replaceAll("&", "_");
	return buildUrl(
		`${term ?? JOB_TERMS[0]}/J${job._id.toUpperCase()}/${search_string}`,
		pageArgs,
		true
	);
};
