import { useState, useEffect } from "react";

export const useScrollResize = (initialHeight: number, minHeight: number) => {
	const [height, setHeight] = useState(initialHeight);

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY - 88;
			const newHeight = Math.max(
				minHeight,
				initialHeight - (scrollY > 0 ? scrollY : 0)
			);
			setHeight(newHeight);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [initialHeight, minHeight]);

	return height;
};

export const useContentScroll = (contentRef, containerRef) => {
	useEffect(() => {
		const handleScroll = (e) => {
			const content = contentRef.current;
			if (content) {
				const { scrollTop, scrollHeight, clientHeight } = content;
				const isScrolledToTop = scrollTop === 0;
				const isScrolledToBottom =
					scrollTop + clientHeight >= scrollHeight - 1;

				// Only block scrolling on page the content scroll is not on the top or bottom
				if (
					!(isScrolledToTop && e.deltaY < 0) &&
					!(isScrolledToBottom && e.deltaY > 0)
				) {
					content.scrollTop += e.deltaY;
					e.preventDefault();
				}
			}
		};

		const container = containerRef.current;
		if (container) {
			container.addEventListener("wheel", handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener("wheel", handleScroll);
			}
		};
	}, [contentRef, containerRef]);
};
