import tw from "tailwind-styled-components";
import { responsiveDisplay } from "../common";

type containerProps = {
	$background_color?: string;
	$display?: string;
};

type flexContainerProps = {
	$vcenter?: boolean;
	$hcenter?: boolean;
	$flex_col?: boolean;
	$no_margin?: boolean;
	$width?: string;
	$text_color?: string;
};

export const Container = tw.section<containerProps>`
    block 
    relative
    ${(variant) =>
		variant.$background_color && `bg-${variant.$background_color}`}
    ${(variant) =>
		variant.$display !== undefined && responsiveDisplay[variant.$display]}
`;

export const FlexContainer = tw.div<flexContainerProps>`
    flex
    ${(variant) => variant.$width === "full" && "max-w-full"}
    ${(variant) => variant.$width === "small" && "max-w-[52rem] mx-auto"}
    ${(variant) => variant.$no_margin !== true && "mx-auto"}
    ${(variant) => variant.$hcenter === true && "items-center [&>*]:m-auto"}
    ${(variant) => variant.$vcenter === true && "justify-center"}
    ${(variant) => variant.$flex_col === true && "flex-col"}
    ${(variant) => variant.$text_color && `text-${variant.$text_color}`}
`;
