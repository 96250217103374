"use client";
import { motion } from "framer-motion";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { scrollProgress } from "../HDIWProgressSection.interface";

const scrollPaths = {
	desktop: "M5 5L5.00007 1514",
	mobile: "m246.89,5.23S22.97-6.84,28.48,144.13l.86,1449.85s-1.77,35.82,37.11,39.05,76.41,24.33,82.53,32.44c0,0-3.03,21.02,17.95,14.27,0,0,28.07,41.8,46.54,92.79",
};

const RenderScrollProgressBar = ({
	mobileView,
	epv_icon,
	progress_bars,
}: {
	mobileView: boolean;
	epv_icon: string;
	progress_bars: scrollProgress["progress_bars"];
}) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const pathLength = mobileView ? 2050 : 1509;
	const handleScroll = () => {
		const position = window.scrollY;
		setScrollPosition(position);
	};
	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const svgLengthScrolled = pathLength > 0 ? scrollPosition / pathLength : 0;

	return (
		<div className="relative -top-20 md:px-5">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height={mobileView ? pathLength - 125 : pathLength}
			>
				<foreignObject
					className="hidden md:block w-full"
					height={pathLength}
					width={"100%"}
				>
					<Image
						src={progress_bars.desktop}
						alt="Desktop Path"
						sizes="100vw"
						width={10}
						height={pathLength}
						priority
						unoptimized
					/>
				</foreignObject>
				<foreignObject
					className="md:hidden"
					height={pathLength}
					width={"337px"}
				>
					<Image
						src={progress_bars.mobile}
						alt="Mobile Path"
						sizes="100vw"
						width={337}
						height={pathLength}
						priority
						unoptimized
					/>
				</foreignObject>
				<motion.path
					d={mobileView ? scrollPaths.mobile : scrollPaths.desktop}
					fill="transparent"
					strokeWidth="10"
					stroke="#88A91E"
					strokeLinecap="round"
					className="path"
					strokeDasharray="0 1"
					animate={{ pathLength: svgLengthScrolled }}
					transition={{
						duration: 0,
					}}
				/>
			</svg>
			<div
				className="w-10 h-12 md:w-14 md:h-[70px] absolute top-0 md:left-5 fill-none bg-contain bg-no-repeat bg-center z-10 !-rotate-90"
				style={{
					offsetPath: `path('${
						mobileView ? scrollPaths.mobile : scrollPaths.desktop
					}')`,
					backgroundImage: `url(${epv_icon})`,
					offsetDistance: `${
						svgLengthScrolled * 100 > 100
							? 100
							: svgLengthScrolled * 100
					}%`,
				}}
			/>
		</div>
	);
};

export default RenderScrollProgressBar;
