import React, { useEffect, useState } from "react";
import { inputFieldProp } from "../../form.interfaces";
import validate_field, { ValidationResponse } from "../validate_field";
import {
	FormHelperText,
	MenuItem,
	SelectChangeEvent,
	FormControl,
	InputLabel,
} from "@mui/material";
import { StyledSelectField } from "../FormStyles";

export const RenderLocationField = ({
	index,
	keyName,
	field,
	setState,
	context,
	args,
	theme,
}: inputFieldProp & { field: { data: { filters: any } } }) => {
	const locationList = context?.locations?.data || [];
	const [fieldState, setFieldState] = useState("");
	const [error, setError] = useState<undefined | ValidationResponse>(
		undefined
	);
	const hasRequiredAsterisk =
		theme?.components?.MuiInputLabel?.styleOverrides?.asterisk?.display !==
		"none";
	const category = field.data.filters.category || "hub";
	let locations = [];
	if (locationList && Array.isArray(locationList)) {
		locations = locationList;
		if (field.data.filters.category == "hub") {
			locations = locations.filter((x) => x.config.hub.active == true);
		} else if (field.data.filters.category == "fc") {
			locations = locations.filter((x) => x.config.fc.active == true);
		}
		if (
			field.data.filters.blacklist &&
			field.data.filters.blacklist.length > 0
		) {
			const blacklist = field.data.filters.blacklist
				.replaceAll(" ", "")
				.split(",");
			locations = locations.filter(
				(x) => !blacklist.includes(x.config[category].abbreviation)
			);
		} else if (
			field.data.filters.whitelist &&
			field.data.filters.whitelist.length > 0
		) {
			const whitelist = field.data.filters.whitelist
				.replaceAll(" ", "")
				.split(",");
			locations = locations.filter((x) =>
				whitelist.includes(x.config[category].abbreviation)
			);
		}
		locations = locations.filter(
			(x) => x.market == field.data.filters.market
		);
		locations = locations.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		});
	}
	const selectedLocation = locations.find(
		(x) =>
			x.name.trim() ===
				decodeURIComponent(args?.query?.location).trim() &&
			!x.config[category].disable_location_selection
	);

	useEffect(() => {
		if (selectedLocation) {
			setFieldState(selectedLocation.config[category].abbreviation);
			setState(
				"data.location",
				selectedLocation.config[category].abbreviation
			);
		}
	}, []);

	const handleChange = (event: SelectChangeEvent) => {
		setFieldState(event.target.value);
		setState(keyName, event.target.value);
    validate_field(field.data.validation, fieldState)
	};
	return (
		<FormControl error={error?.error} fullWidth size="small">
			<InputLabel id={field.data.label}>
				{field.data.label}{" "}
				{field.data.validation?.required && hasRequiredAsterisk && " *"}
			</InputLabel>
			<StyledSelectField
				labelId={field.data.label}
				id={keyName}
				label={field.data.label}
				onChange={handleChange}
				multiple={field.data.variant == "multiselect"}
				required={!!field.data.validation?.required}
				onBlur={(e) => {
					setError(validate_field(field.data.validation, fieldState));
				}}
				onFocus={(e) => {
					setError(undefined);
				}}
				error={error?.error}
				size="small"
				defaultValue={
					selectedLocation?.config[category]?.abbreviation ||
					undefined
				}
			>
				{locations &&
					Object.values(locations).map(
						(location: any, index: number) => {
							const value =
								location.config[category].abbreviation;
							const disabled =
								location.config[category]
									.disable_location_selection == true;
							return (
								<MenuItem
									key={index}
									value={value}
									disabled={disabled}
								>
									{location.name} {disabled ? "*" : ""}
								</MenuItem>
							);
						}
					)}
			</StyledSelectField>
			{error?.error && (
				<FormHelperText>{error.error_text}</FormHelperText>
			)}
		</FormControl>
	);
};
