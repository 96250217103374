"use client";

import React from "react";
import { List, ListItem } from "../list.interfaces";
import { Container, FlexContainer } from "../../../styles/components";
import RenderTextBlock from "../../Text";

const RenderHorizontalBar = ({ items, advanced }: List) => {
	return (
		// eslint-disable-next-line
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className="list"
		>
			<FlexContainer
				$hcenter={true}
				$full_width={true}
				className="gap-3 bg-green1 py-2 text-white"
			>
				<ul>
					{items.map((item: ListItem, key: number) => {
						return (
							<li
								className={`${
									key !== 0 && "hidden"
								} md:inline-block`}
								key={key}
							>
								<div className="mx-6 inline-block">&bull;</div>
								<div className="inline-block">
									<RenderTextBlock
										data={{ content: item.text }}
									/>
								</div>
							</li>
						);
					})}
				</ul>
			</FlexContainer>
		</Container>
	);
};

export default RenderHorizontalBar;
