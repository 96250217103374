"use client";

import React from "react";
import { AllData } from "../../interfaces/blocks";
import RenderList from "./variants/RenderList";
import RenderSmallCard from "./variants/RenderSmallCard";
import RenderHorizontalBar from "./variants/RenderHorizontalBar";
import RenderCheckList from "./variants/RenderCheckList";
import { List } from "./list.interfaces";
import RenderBulletList from "./variants/RenderBulletList";

type listProps = {
	data: List;
	mobileView: boolean;
};

const listVariantMap: Record<string, React.FC<List>> = {
	normal: RenderList,
	small_cards: RenderSmallCard,
	horizontal_bar: RenderHorizontalBar,
	check_list: RenderCheckList,
	bullet_list: RenderBulletList,
};

const RenderListComponent = ({ data, mobileView }: listProps) => {
	const { items, advanced, styling } = data;
	const List =
		listVariantMap[styling?.variant ? styling?.variant! : "normal"];

	return (
		<List
			items={items}
			advanced={advanced}
			styling={styling}
			mobileView={mobileView}
		/>
	);
};

export default RenderListComponent;
