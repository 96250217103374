import React from "react";
import {
	format,
	startOfWeek,
	addDays,
	startOfMonth,
	addMonths,
} from "date-fns";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ChevronRight } from "@mui/icons-material";

const RenderMonthlyView = ({
	component,
	date,
	setDate,
	view,
	setView,
	daysOfWeek,
	events,
}) => {
	const prev = () => {
		setDate(addMonths(date, -1));
	};

	const next = () => {
		setDate(addMonths(date, 1));
	};

	const renderDays = () => {
		return [...Array(6)].map((_, week) => (
			<div key={week} className="grid grid-cols-7">
				{[...Array(daysOfWeek.length)].map((_, dayOfWeek) => {
					const day = addDays(
						startOfWeek(startOfMonth(date)),
						week * 7 + dayOfWeek
					);
					const isCurrentMonth = day.getMonth() === date.getMonth();

					const filteredEvents = events.filter((event) => {
						const startDateTime = new Date(event.start);
						return (
							startDateTime.getMonth() === day.getMonth() &&
							startDateTime.getDate() === day.getDate()
						);
					});

					return (
						<div
							key={day.toISOString()}
							className="p-2 text-sm border border-gray-200 text-red-400"
							style={{ minHeight: "100px" }}
						>
							<div className="font-medium">
								<Typography variant="body2">
									{format(
										day,
										isCurrentMonth ? "d" : "MMM d"
									)}
								</Typography>
							</div>
							<div>
								{filteredEvents.map((event) => {
									const startDateTime = new Date(event.start);
									const hoursAndMinutes =
										startDateTime.toLocaleTimeString(
											"en-US",
											{
												hour: "numeric",
												minute: "2-digit",
											}
										);

									return (
										<div
											key={event.start + event.title}
											className={`rounded-lg p-2 my-2 w-full break-all`}
											style={{
												backgroundColor:
													event.background_color,
											}}
										>
											<div
												className={`font-semibold`}
												style={{
													color: event.text_color,
												}}
											>
												{(component.styling.monthly
													.show_times
													? `${hoursAndMinutes} - `
													: "") + event.title}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		));
	};

	return (
		<div className="p-4">
			{component.styling.monthly?.show_controls && (
				<div className="flex items-center justify-between py-4">
					<Typography variant="body1" className="font-medium">
						{format(date, "MMMM yyyy")}
					</Typography>
					<div>
						<button
							className="p-2 rounded-lg hover:bg-gray-100"
							onClick={prev}
						>
							<ChevronLeftIcon className="h-6 w-6 text-gray-500"></ChevronLeftIcon>
						</button>
						<button
							className="p-2 rounded-lg hover:bg-gray-100 ml-2"
							onClick={next}
						>
							<ChevronRight className="h-6 w-6 text-gray-500"></ChevronRight>
						</button>
					</div>
				</div>
			)}
			<div className="grid grid-cols-7 gap-4">
				{daysOfWeek.map((day) => (
					<div key={day} className="text-center">
						<Typography variant="body2" className="font-medium">
							{day}
						</Typography>
					</div>
				))}
			</div>
			<div className="flex flex-col">{renderDays()}</div>
		</div>
	);
};

export default function MonthlyView({
	component,
	date,
	setDate,
	view,
	setView,
	daysOfWeek,
	events,
}) {
	return (
		<div>
			<RenderMonthlyView
				component={component}
				date={date}
				setDate={setDate}
				view={view}
				setView={setView}
				daysOfWeek={daysOfWeek}
				events={events}
			/>
		</div>
	);
}
