import { Job } from "../joblist.interfaces";

export const getJobKeyFromNestedFilter = (job: Job, filter: string): string => {
	const nestedFilterArray = filter.split(".");
	let filterObjectKey = "";
	nestedFilterArray.forEach((nestedFilter) => {
		filterObjectKey =
			filterObjectKey == ""
				? job[nestedFilter]
				: filterObjectKey[nestedFilter];
	});
	return filterObjectKey;
};
