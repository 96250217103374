"use client";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import { getBounds } from "@/utils/locAndMap";
import { StyledTextField } from "../Form/partials/FormStyles";
import React, { useEffect, useRef, useState } from "react";

export const RenderAddressSearch = ({
	locations,
	states,
	placeholder,
	label,
	variant,
	initialValue = null,
	searchBtn = false,
}) => {
	const [initValue, setInitValue] = useState(initialValue);
	const [searchBtnVisible, setSearchBtnVisible] = useState(null);
	const suggestionsRef = useRef(null);
	const searchButtonRef = useRef(null);
	const isMap = variant === "map";

	useEffect(() => {
		const handleOutSideClick = (event) => {
			if (
				!suggestionsRef.current?.contains(event.target) &&
				!searchButtonRef.current?.contains(event.target)
			) {
				clearSuggestions();
			}
		};
		window.addEventListener("mousedown", handleOutSideClick);
		return () => {
			window.removeEventListener("mousedown", handleOutSideClick);
		};
	}, [suggestionsRef]);

	const handleSelectLoc = async (place) => {
		try {
			const results = await getGeocode({ address: place });
			const { lat, lng } = await getLatLng(results[0]);
			states.selectedPlace.set({
				description: place,
				lat,
				lng,
			});
			if (isMap) {
				states.map.value.panTo({ lat, lng });
				states.mapZoom.set(12);
			}
		} catch (error) {
			console.error("Error selecting place:", error);
		}
	};

	const handleSelect = (place) => {
		clearSuggestions();
		setValue(place, false);
		handleSelectLoc(place);
	};

	const selectFirstOption = () => {
		if (suggestionsRef.current) {
			const firstChild = suggestionsRef.current.firstChild || null;
			if (firstChild) {
				handleSelect(firstChild.textContent);
			}
		}
	};

	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		requestOptions: {
			locationRestriction: getBounds(locations),
		},
		debounce: 100,
	});

	const handleInputChange = (e) => {
		setValue(e.target.value);
		setInitValue(null);
	};

	useEffect(() => {
		if (initialValue) {
			setValue(initialValue);
			clearSuggestions();
		}
	}, []);

	const RenderSuggestions = () => {
		return (
			<div
				ref={suggestionsRef}
				className="mt-2 max-w-full absolute bg-white border border-grey2 rounded drop-shadow w-full overflow-hidden"
				style={{ zIndex: 98 }}
			>
				{data.slice(0, 4).map((suggestion) => {
					return (
						<div
							key={suggestion.place_id}
							onClick={() => handleSelect(suggestion.description)}
							className="cursor-pointer hover:bg-grey1 px-3 py-1 w-full border-0 [&:not(:last-child)]:border-b border-grey1"
						>
							{suggestion.description}
						</div>
					);
				})}
			</div>
		);
	};

	const SearchButton = () => {
		return (
			<div className={"absolute right-0 p-1.5 h-full z-50"}>
				<div
					ref={searchButtonRef}
					onClick={() => selectFirstOption()}
					className={
						"rounded-sm bg-grey3 text-sm text-white h-full text-center align-middle px-2 flex cursor-pointer"
					}
				>
					<div className={"my-auto text-xs"}>{searchBtn}</div>
				</div>
			</div>
		);
	};

	return (
		<div className={"relative"}>
			{searchBtn && searchBtnVisible && <SearchButton />}
			<StyledTextField
				label={label}
				placeholder={placeholder}
				type="text_input"
				required={false}
				value={value}
				onChange={handleInputChange}
				onFocus={() => setSearchBtnVisible(true)}
				onBlur={() => {
					!value && setSearchBtnVisible(false);
				}}
				size="small"
				fullWidth
				inputProps={{
					autoComplete: "off",
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
						selectFirstOption();
					}
				}}
			/>

			{status === "OK" && data.length && !initValue && (
				<RenderSuggestions />
			)}
		</div>
	);
};
