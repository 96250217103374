export const flattenObject = (
	obj: object,
	parentKey?: string,
	prefix?: string
) => {
	let result = {};
	if(obj) {
		Object.keys(obj).forEach((key) => {
			const value = obj[key];
			const _key = parentKey ? parentKey + "." + key : key;
			if (value != null) {
				if (typeof value === "object") {
					result = { ...result, ...flattenObject(value, _key, prefix) };
				} else {
					result[(prefix ? prefix : "") + _key] = value;
				}
			}
		});
	}
	return result;
};

export function unflattenObject(
	flatObj: Record<string, any>
): Record<string, any> {
	const result: Record<string, any> = {};

	for (const key in flatObj) {
		const keyParts = key.split(".");
		let currentLevel = result;

		for (let i = 0; i < keyParts.length; i++) {
			const part = keyParts[i];
			if (!currentLevel[part]) {
				if (i === keyParts.length - 1) {
					currentLevel[part] = flatObj[key];
				} else {
					currentLevel[part] = {};
				}
			}
			currentLevel = currentLevel[part];
		}
	}

	return result;
}

export function setValue(
	obj: Record<string, any>,
	key: string,
	value: any
): void {
	const keyParts = key.split(".");
	let currentLevel = obj;

	for (let i = 0; i < keyParts.length; i++) {
		const part = keyParts[i];
		const isLastPart = i === keyParts.length - 1;

		if (Array.isArray(currentLevel)) {
			const index = parseInt(part, 10);

			if (isNaN(index)) {
				throw new Error(`Invalid array index: ${part}`);
			}

			if (isLastPart) {
				// If it's the last part, set the value at the specified index
				currentLevel[index] = value;
			} else {
				// If the next part is not a number, create an object
				const nextPartIsNumber = !isNaN(Number(keyParts[i + 1]));

				if (!currentLevel[index]) {
					currentLevel[index] = nextPartIsNumber ? [] : {};
				}
			}

			// Set the current level to the element in the array
			currentLevel = currentLevel[index];
		} else {
			if (isLastPart) {
				// If it's the last part, set the value
				currentLevel[part] = value;
			} else {
				// If the next part is a number, create an array
				const nextPartIsNumber = !isNaN(Number(keyParts[i + 1]));

				if (!currentLevel[part]) {
					currentLevel[part] = nextPartIsNumber ? [] : {};
				}
			}

			// Set the current level to the property in the object
			currentLevel = currentLevel[part];
		}
	}
}
