import { RenderRadioField } from "./RadioInputField";
import { RenderCheckBoxField } from "./CheckBoxInputField";
import { RenderSelectField } from "./SelectInputField";
import { RenderTextInputField } from "./TextInputField";
import { RenderFileUploadInputField } from "./FileUploadInputField";
import { RenderDateInputField } from "./DateInputField";
import { inputFieldProp } from "../../form.interfaces";
import { RenderAddressInputField } from "./AddressInputField";
import { RenderLocationField } from "./LocationInputField";

const FormTypeInputFieldMap: Record<string, React.FC<inputFieldProp>> = {
	// We will use TextField for string, number, integer and boolean
	text_input: RenderTextInputField,
	tel_input: RenderTextInputField,
	email_input: RenderTextInputField,
	date_input: RenderDateInputField,
	select_input: RenderSelectField,
	radio_input: RenderRadioField,
	checkbox_input: RenderCheckBoxField,
	fileUpload_input: RenderFileUploadInputField,
	address_input: RenderAddressInputField,
	location_input: RenderLocationField,
};

export default FormTypeInputFieldMap;
