const CardClassNames = (styling, clickable) => {
	const textPosition =
		styling?.text_position == undefined ? "normal" : styling?.text_position;

	const imagePosition =
		styling?.image_position == undefined ? "top" : styling?.image_position;
	const imagePositionMobile =
		styling?.image_position_mobile == undefined
			? imagePosition
			: styling?.image_position_mobile;

	const cardPadding =
		styling?.content_padding != undefined
			? styling?.content_padding
			: "normal";

	const justifyContent =
		styling?.justify_content != undefined
			? styling?.justify_content
			: "center";

	const mediaGrows = styling?.media_sizing?.variant === "grow";

	const cardSizingMap: Record<string, string> = {
		cool: "w-72 h-56",
		sm: "h-[150px]",
		md: "w-72 h-38",
		lg: "w-[400px]",
		xl: "w-full md:w-[600px] md:h-40",
		full: "w-full h-full",
	};

	return {
		card: `flex
            ${
				imagePositionMobile === "left"
					? "flex-row md:flex-col"
					: "flex-col"
			}
            ${imagePosition === "card" && "!overflow-visible"}
            ${cardSizingMap[styling?.sizing]}
            ${clickable && "cursor-pointer"}
            ${
				styling?.rounded_size
					? styling?.rounded_size
					: "!rounded md:!rounded-3lg"
			}
            ${imagePosition === "icon" && "!flex-row"}
            
            ${
				styling?.animated
					? "hover:scale-[0.97] md:hover:scale-[1.02] !transition-all !duration-150"
					: ""
			}`,
		cardInnerDiv: `
            ${mediaGrows && "h-full"}
            ${imagePosition === "icon" && "self-center"}
            ${imagePosition === "title" && "flex items-center"}
            ${imagePositionMobile === "left" && "w-1/3 md:w-full"}`,
		cardContent: `${!mediaGrows && "flex-1"} ${
			textPosition !== "normal" && "h-full"
		} ${cardPadding === "normal" ? "md:px-6 py-4" : cardPadding} ${
			imagePosition === "card" && "rounded bg-grey1 pt-20 md:pt-4"
		}`,
		cardContentInnerDiv: `relative flex h-full justify-center gap-4
          ${
				styling?.chevron_button && styling?.chevron_button === "side"
					? "flex-row"
					: "flex-col"
			}
          ${justifyContent === "between" ? "!justify-between" : ""}
          ${
				imagePosition === "card"
					? `text-center md:float-right md:text-left 
              ${
					styling?.no_padding
						? "md:w-[60%] md:max-w-[60%]"
						: "md:w-[54%] md:max-w-[54%]"
				} [&_button]:m-auto md:[&_button]:m-0`
					: ""
			}`,
		cardChevron: `chevron_button ${
			styling?.chevron_button === "bottom"
				? "fit-content float-right mt-4 w-fit self-end rounded-full bg-red1 p-0.5 text-white"
				: "aspect-square h-8 w-8 flex items-center justify-center float-right rounded-full bg-red1 text-white"
		}`,
	};
};

export default CardClassNames;
