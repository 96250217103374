export const getFilterObject = (
	filterArray: string[]
): Record<string, number> => {
	const filterObject: Record<string, number> = {};
	for (const filter of filterArray) {
		filterObject[filter] = filterObject[filter]
			? filterObject[filter] + 1
			: 1;
	}

	return filterObject;
};
