/* eslint-disable @next/next/no-img-element */
"use client";

import React from "react";
import { htmlspecialchars_decode, htmlToReactParser } from "../../../utils";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Link from "next/link";
import { usePathname } from "next/navigation";

const isWithinWorkHours = () => {
	let hour = new Date().getHours();
	return hour >= 9 && hour <= 17;
};

const RenderContactCard = ({ data, mobileView }) => {
	const { heading, content, media_src, clickable } = data;
	const media = media_src?.desktop ? media_src?.desktop : media_src?.mobile;
	const wrapperClasses = mobileView
		? "py-4 border-b border-grey1 border-bottom last-of-type:border-b-0 last-of-type:pb-3"
		: "shadow min-w-64 p-4 rounded";

	const isWhatsapp = heading.toLowerCase().includes("whatsapp");
	const showSupportTag = isWhatsapp && mobileView && isWithinWorkHours();
	const siteLang = usePathname().split("/")[1];
	const supportTextMap = {
		en: "Now online",
		nl: "Nu online",
		fr: "En ligne",
		de: "Jetzt online",
	};

	const ClickWrapper = ({ children }) => {
		return clickable && clickable.href ? (
			<Link
				href={clickable.href}
				prefetch={false}
				className={wrapperClasses}
			>
				{" "}
				{children}{" "}
			</Link>
		) : (
			<>{children}</>
		);
	};

	return (
		<ClickWrapper>
			<div
				className={`relative flex gap-4 w-full max-w-screen-sm ${
					!clickable && wrapperClasses
				}`}
			>
				<img
					src={media?.src}
					alt={media?.type}
					width={24}
					height={24}
					className="object-fill"
				/>
				<div>
					<span className="flex items-center">
						{htmlToReactParser(heading)}
						{showSupportTag && (
							<span className="inline-block bg-green5 text-green1 text-sm font-bold ml-4 rounded-sm p-1">
								{supportTextMap[siteLang]}
							</span>
						)}
					</span>
					{!mobileView && htmlToReactParser(content)}
				</div>
				{mobileView && (
					<KeyboardArrowRightRoundedIcon
						className="ml-auto self-center"
						fontSize="medium"
					/>
				)}
			</div>
		</ClickWrapper>
	);
};

export default RenderContactCard;
