import { styled } from "@mui/material";
import { DateField } from "@mui/x-date-pickers/DateField";

export const StyledDateField = styled(DateField)(() => ({
	"&>*:nth-child(2)": {
		borderRadius: "8px",
	},
	"&>label": {
		lineHeight: "unset",
	},
}));
