import React from "react";
import { Text } from "./text.interfaces";
import { Container, FlexContainer } from "../../styles/components";
import { htmlToReactParser, htmlspecialchars_decode } from "../../utils";
import StyledText from "./text.styles";

type TextProps = {
	data: Text;
};

const RenderTextBlock = ({ data }: TextProps) => {
	const {
		content,
		advanced,
		styling,
		width_restriction,
		content_background,
	} = data;
	let parsedContent = htmlspecialchars_decode(content);
	if (content_background) {
		parsedContent = parsedContent.replace("pt-12 md:pt-0", "pt-4 md:pt-8");
	}

	return (
		// @ts-ignore
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className="text"
			$background_color={styling?.background_color}
			$display={styling?.display}
		>
			<FlexContainer
				$flex_col={true}
				$width={styling?.width}
				$no_margin
				$text_color={styling?.text_color}
				className="relative"
			>
				<StyledText
					$justify={styling?.justify_text}
					$alignment={styling?.alignment}
					$gap={styling?.gap}
					$flex={styling?.flex !== false}
					$width_restriction={width_restriction}
					className="text-block"
				>
					{content_background && (
						<div
							style={{ borderRadius: "16px 16px 0px 0px" }}
							className="absolute bg-white w-[105%] md:w-[115%] h-[125%] top-0 left-[-2.5%] md:left-[-7.5%]"
						></div>
					)}

					<div
						className={`z-10 ${
							styling?.shadow == true &&
							"text-shadow-lg shadow-black"
						}`}
					>
						{htmlToReactParser(parsedContent)}
					</div>
				</StyledText>
			</FlexContainer>
		</Container>
	);
};

export default RenderTextBlock;
