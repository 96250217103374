import dynamic from "next/dynamic";

const ComponentMap = {
	hdiwProgress: {
		element: dynamic(() => import("./components/HDIWProgressSection"), {
			ssr: false,
		}),
		context: [],
	},
	aboutUs: {
		element: dynamic(() => import("./components/AboutUs"), { ssr: false }),
		context: [],
	},
	consumerHero: {
		element: dynamic(() => import("./components/ConsumerHero"), {
			ssr: true,
		}),
		context: [],
	},
	recipe: {
		element: dynamic(() => import("./components/Recipe"), { ssr: false }),
		context: [],
	},
	cookbook: {
		element: dynamic(() => import("./components/Cookbook"), {ssr: true}),
		context: [],
	},
	cta: {
		element: dynamic(() => import("./components/CTA"), { ssr: false }),
		context: [],
	},
};

export default ComponentMap;
