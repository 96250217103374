"use client"
import { htmlToReactParser } from "@/shared/utils";
import dynamic from "next/dynamic";
import RenderButtonsComponent from "@/shared/components/Button";
import animData from "@/public/assets/consumer/lottie/epv_no_foodies.json";

const RenderAnimatedPlayerComponent = dynamic(
    () => import("@/components/AnimatedPlayer"),
    { ssr: false }
);

interface CTAData {
    cta_block: {
        mobile_text: string;
        desktop_text: string;
    };
    buttons: any;
    styling: any;
}

interface RenderCTAProps {
    data: CTAData;
    mobileView: boolean;
    context: any;
}

const RenderCTA: React.FC<RenderCTAProps> = ({ data, mobileView, context }) => {
    const { cta_block, buttons, styling } = data;
    return (
        <div className="flex flex-wrap justify-center items-center gap-4 md:gap-16 md:py-20 py-12 px-4">
            <div className="w-40 h-28 md:w-[400px] md:h-72 flex justify-center">
                {animData && (
                    <RenderAnimatedPlayerComponent
                        data={{ animation_data: animData }}
                    />
                )}
            </div>
            <div className="flex flex-col md:gap-4 gap-2 items-start justify-center max-w-full md:max-w-[300px] md:pt-24 pt-8">
                {htmlToReactParser(
                    mobileView
                        ? cta_block.mobile_text
                        : cta_block.desktop_text
                )}
                <div className="flex flex-wrap gap-2">
                    <RenderButtonsComponent
                        data={{ buttons, styling }}
                    />
                </div>
            </div>
        </div>
    );
};

export default RenderCTA;
