import { isEmpty } from "./isEmpty";

export const mediaParseLogic = (mobileView, media_src) => {
	// the media switching follows these rules:
	// 1. if there is no mobile, then it uses desktop (works the other way around too)
	// 2. desktop/mobile can have an individual type (video/image)
	// 3. it counts an empty src string as no media, then switches to opposite (mobile/desktop)
	// TODO: if both are empty, show placeholder image
	var currentMedia = media_src.desktop;
	if (
		!isEmpty(media_src.mobile?.src) &&
		(mobileView || isEmpty(currentMedia?.src))
	) {
		currentMedia = media_src?.mobile;
	}
	return currentMedia;
};
