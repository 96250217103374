import React from "react";
import RenderTextBlock from "@/components/Text";
import RenderRowComponent from "@/components/Row";
import { compMapping } from "@/app/MapBlock";
import RenderMediaComponent from "@/components/Media";
import {
	AccessTime,
	CalendarMonth,
	ChevronLeft,
	Person,
} from "@mui/icons-material";
import RenderShareModalComponent from "../ShareModal";
import Link from "next/link";
import './blogs.css';

type BlogsProps = {
	data: any;
	mobileView: boolean;
	context?: any;
};

function formatDate(date) {
	const dateObject = new Date(date);
	const options = { day: "numeric", month: "short", year: "numeric" };
	const dateString = dateObject.toLocaleDateString("en-US", options);
	const timeString =
		dateObject.getHours().toString().padStart(2, "0") +
		":" +
		dateObject.getMinutes().toString().padStart(2, "0");
	return dateString + " " + timeString;
}

const RenderBlogContentComponent = async ({
	data,
	mobileView,
	context,
}: BlogsProps) => {
  const blogList = context?.blogs?.data || [];
	const blog = blogList.find((obj: any) => {
    return obj.slug === data.blog_slug;
	});

	const extraComponents: any = compMapping["jobs"];
  let blogContent = blog?.content?.replaceAll("\n", "<br>").replaceAll('<br><br>', '<br>') ?? "";
  blogContent = blogContent.replace(/(\sstyle="[^"]*")/gi, '');
	return blog ? (
		<>
			<div className="bg-grey1 w-full blogs">
				<div className="max-w-full md:max-w-[816px] mx-auto py-8 px-6">
					<div className="pb-8">
						<Link href="/blogs-and-events">
							<ChevronLeft />
							Back
						</Link>

						{mobileView && (
							<div className="flex flex-grow justify-end absolute top-5 right-5">
								<RenderShareModalComponent
									data={{
										title: blog.title,
										link: `https://jobs.picnic.app/blogs/${blog.slug}`,
									}}
									mobileView={mobileView}
								/>
							</div>
						)}
					</div>
					<h2>
						<strong>{blog.title}</strong>
					</h2>
					<div className="flex gap-3 items-center py-6">
						<div className="flex gap-6 text-grey3 text-sm justify-between">
							<span className="flex gap-1">
								<Person fontSize="small" />
								Written by {blog.author.name}
							</span>
							<span className="flex gap-1">
								<CalendarMonth fontSize="small" />
								{formatDate(blog.created_at)}
							</span>
							<span className="flex gap-1">
								<AccessTime fontSize="small" />
								{blog.estimated_read_time} min read
							</span>
						</div>
						{!mobileView && (
							<div className="flex flex-grow justify-end">
								<RenderShareModalComponent
									data={{
										title: blog.title,
										link: `https://jobs.picnic.app/blogs/${blog.slug}`,
									}}
									mobileView={mobileView}
								/>
							</div>
						)}
					</div>

					<div>
						<RenderMediaComponent
							data={{
								media_src: {
									desktop: {
										src: blog.thumbnail,
										type: "image",
									},
								},
								styling: {
									rounded_corners: true,
									rounded_size: "rounded-3lg",
								},
							}}
							mobileView={mobileView}
						/>
					</div>
				</div>
			</div>
			<div className="max-w-full md:max-w-[816px] mx-auto py-8 px-6">
				<RenderTextBlock
					data={{
						content: blogContent,
						styling: {
							full_width: true,
						},
					}}
				/>
				<div className="pt-6">
					<RenderRowComponent
						data={{
							columns: [
								{
									components: [
										{
											type: "banner",
											data: {
												content: `<h4 class="text-xl"><strong>Want to join ${blog.author.name} in finding solutions to interesting problems?</strong></h4>\n`,
												media_src: {
													desktop: {
														src: "https://d2jxuf8ovdiw8x.cloudfront.net/uploads/sites/18/2024/05/picnic-blog-job-banner-image.jpg",
														type: "image",
													},
													mobile: {
														src: "",
														type: "image",
													},
												},
												buttons: [
													{
														text: "<p>Apply directly</p>",
														link: "https://jobs.picnic.app",
														target: "_blank",
														icon: "",
														styling: {
															background_color:
																"green1",
															text_color: "white",
															rounded: "sm",
															padded: false,
															sizing: "free",
														},
													},
												],
												styling: {
													image_position: "card",
													text_position: "full",
													sizing: "full",
													media_sizing: {
														desktop: {
															width: "w-full",
															height: "h-64",
														},
													},
													no_shadows: true,
													full_width: true,
													no_padding: true,
												},
											},
										},
									],
									styling: {
										vcenter: true,
										hcenter: true,
									},
								},
							],
							styling: {
								full_width: true,
								no_margin: true,
							},
						}}
						mobileView={mobileView}
						extraComponents={extraComponents}
						context={context}
					/>
				</div>
			</div>

			<RenderRowComponent
				data={{
					columns: [
						{
							components: [
								{
									type: "text",
									data: {
										content:
											"<h2><b>Recent blog posts</b></h2><p>We're strong believers in learning from each other, so</br>our employees write about what interests them.</p>",
										alignment: "left",
									},
								},
								{
									type: "blogs",
									data: {},
								},
							],
							styling: {},
						},
					],
				}}
				mobileView={mobileView}
				extraComponents={extraComponents}
				context={context}
			/>
		</>
	) : null;
};

export default RenderBlogContentComponent;
