import { FooterSocials } from "../../footer.interfaces";
import Link from "next/link";
import React, { FunctionComponent } from "react";
import {
	Instagram,
	FacebookRounded,
	LinkedIn,
	WhatsApp,
	SvgIconComponent,
	Twitter,
} from "@mui/icons-material";

const { library } = require("@fortawesome/fontawesome-svg-core");

type socialButtonProps = {
	socials: FooterSocials[];
};

const socialIconMap: Record<string, SvgIconComponent> = {
	facebook: FacebookRounded,
	instagram: Instagram,
	whatsapp: WhatsApp,
	linkedIn: LinkedIn,
	twitter: Twitter,
};

const RenderSocialButton = (social: FooterSocials, key: number) => {
	const SocialButton: SvgIconComponent = socialIconMap[social.type];
	if (
		socialIconMap[social.type] !== undefined ||
		socialIconMap[social.type]
	) {
		return (
			<Link
				prefetch={false}
				key={key}
				href={social.link}
				aria-label={social.type + " Link"}
			>
				<SocialButton fontSize="small" />
			</Link>
		);
	}
	return null;
};

const RenderSocialButtonsComponent = ({ socials }: socialButtonProps) => {
	return (
		<div className="flex gap-6 w-full items-center justify-start">
			{socials &&
				socials.map((social, index) => {
					return RenderSocialButton(social, index);
				})}
		</div>
	);
};

export default RenderSocialButtonsComponent;
