import RenderSliderComponent from "@/components/Slider";
import React from "react";

type BlogsProps = {
	data: any;
	mobileView: boolean;
	context?: any;
};

const RenderBlogsComponent = async ({ data, mobileView, context }: BlogsProps) => {
	const blogList = context?.blogs?.data || [];
	return (
		<RenderSliderComponent
			data={{
				items: blogList.map((blog: any) => ({
					type: "card",
					data: {
						content: blog.title,
						media_src: {
							desktop: {
								src: blog.thumbnail,
								type: "image",
							},
						},
						iconned_content: [
							{
								icon: "user",
								content: `Written by ${blog.author.name}`,
							},
						],
						clickable: {
							href: `/blogs/${blog.slug}`,
						},
						styling: {
							no_padding: true,
							media_sizing: {
								variant: "grow",
								desktop: {
									width: "w-full",
									height: "md:min-h-52 min-h-36",
								},
							},
						},
					},
				})),
				styling: {
					itemsPerRow: {
						xs: 1,
						sm: 1,
						md: 3,
						lg: 3,
					},
					itemSizing: {
						desktop: "auto",
						mobile: "auto",
					},
					hasArrows: true,
				},
			}}
			mobileView={mobileView}
		/>
	);
};

export default RenderBlogsComponent;
