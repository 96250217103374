"use client";

import React from "react";
import { Card } from "../card.interfaces";
import RenderButtonsComponent from "../../Button";
import RenderTextBlock from "../../Text";

type cardTextProps = {
	cardText: {
		content: string;
		textPosition: string;
		textColor: string;
		textJustify: string;
	};
	buttons: Card["buttons"];
	styling: Card["styling"];
};

const cardTextClassList: Record<
	string,
	Record<"innerClass" | "outerClass", string>
> = {
	center: {
		innerClass:
			"rounded bg-white max-w-full mx-0 my-auto break-words text-center",
		outerClass: "flex justify-center items-center h-full",
	},
	bottom: {
		innerClass:
			"rounded bg-white mx-auto my-auto max-w-fit break-words rounded-small px-2",
		outerClass: "absolute bottom-0 w-full text-center",
	},
	left: {
		innerClass: "text-left",
		outerClass: "",
	},
	default: {
		innerClass: "break-words",
		outerClass: "",
	},
};

const RenderCardText = ({ cardText, buttons, styling }: cardTextProps) => {
	const { content, textPosition, textColor, textJustify } = cardText;
	const { outerClass, innerClass } = cardTextClassList[textPosition]
		? cardTextClassList[textPosition]
		: cardTextClassList["default"];

	return (
		<div className={outerClass}>
			<div className={innerClass + ` text-${textColor}`}>
				<RenderTextBlock
					data={{
						content: content,
						styling: { gap: "0", justify_text: textJustify },
					}}
				/>
				<div className="flex w-full flex-col items-center">
					{styling?.separate_buttons == false && buttons && (
						<RenderButtonsComponent
							className="mt-2"
							data={{ buttons: buttons }}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default RenderCardText;
