"use client";

import React from "react";
import Image from "next/image";
import { ListItem, List } from "../list.interfaces";
import { getMediaSrc } from "../../../utils";
import { Card, Container, FlexContainer } from "../../../styles/components";
import RenderTextBlock from "../../Text";

const RenderSmallCard = ({ items, advanced, styling, mobileView }: List) => {
	const renderListCard = (item: ListItem, index: number) => {
		if (mobileView && index > 5) {
			return null;
		}
		const mediaURL = getMediaSrc(item.media_src, mobileView);
		return (
			<Card
				key={index}
				$vcenter={true}
				className={`!m-0 h-auto w-36 gap-1 bg-white px-2 py-2.5 ${
					mobileView && "min-w-36"
				}`}
			>
				<Image
					src={mediaURL}
					width={32}
					height={32}
					alt={item.media_src[mobileView ? "mobile" : "desktop"].type}
				/>
				<div className="text-center text-xs leading-tight px-3 md:max-w-32">
					<RenderTextBlock data={{ content: item.text }} />
				</div>
			</Card>
		);
	};

	return (
		// eslint-disable-next-line
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className="list items-center"
		>
			<FlexContainer
				$flex_col={!mobileView}
				className={`gap-1 md:max-w-4xl`}
			>
				<FlexContainer
					$no_margin={true}
					$vcenter={!mobileView}
					className={`${
						mobileView &&
						"overflow-x-scroll px-3 py-3 -my-1 [&::-webkit-scrollbar]:hidden"
					} 
            ${
				!mobileView && "flex-wrap my-5"
			} gap-4 md:gap-4 [&>*]:m-0 md:[&>*]:m-1`}
				>
					{items.map((item: ListItem, index: number) => {
						return renderListCard(item, index);
					})}
				</FlexContainer>
			</FlexContainer>
		</Container>
	);
};

export default RenderSmallCard;
