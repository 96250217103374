import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface PhoneInputProps {
	value: string;
	onChange: (value: string) => void;
	placeholder?: string;
	error?: string;
}

const FlagPhoneInput: React.FC<PhoneInputProps> = ({
	value,
	onChange,
	placeholder,
	error,
}) => {
	return (
		<div className="relative w-full">
			<PhoneInput
				international
				countryCallingCodeEditable={true}
				defaultCountry="DE"
				value={value}
				onChange={onChange}
				placeholder={placeholder || "Enter phone number"}
				className="flex items-center border border-green6 rounded-l p-2 [&_.PhoneInputInput:focus]:outline-none"
			/>
			{error && <p className="text-red1 text-sm mt-1">{error}</p>}
		</div>
	);
};

export default FlagPhoneInput;
