import tw from "tailwind-styled-components";
import { tailwindHAlignMap } from "../../utils";

type styledMediaProps = {
	$styling?: {
		variant?: string;
		alignment?: "left" | "right" | "center";
	};
};

const StyledMedia = tw.div<styledMediaProps>`
    flex
    h-full
    ${(variant) =>
		variant.$styling?.alignment
			? tailwindHAlignMap[variant.$styling.alignment]
			: tailwindHAlignMap["center"]}
    ${(variant) =>
		variant.$styling?.variant === "padded" &&
		"mx-6 my-12 md:my-20 md:mx-28"}
`;

export default StyledMedia;
