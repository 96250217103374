"use client";
import React, { useState, useEffect, useRef } from "react";
import { TilesContainer, Tile } from "./tyles.styles";
import { Tiles } from "./tile.interfaces";
import { AllData } from "../../interfaces/blocks";

type TileProps = AllData<Tiles>;

const RenderTilesComponent = ({ data }: TileProps) => {
	const { tiles, advanced } = data;

	let observer;
	const componentRef = useRef(null);
	const [animated, setIsAnimated] = useState("");

	useEffect(() => {
		if (window.innerWidth < 768) return;

		const observerOptions = {
			root: null, // use viewport as the root
			rootMargin: "-200px", // restrict to within the page
			threshold: 0.2,
		};

		const intersectionCallback = (entries) => {
			entries.forEach((entry) => {
				entry.isIntersecting
					? setIsAnimated("translate-x-0")
					: setIsAnimated("");
			});
		};

		observer = new IntersectionObserver(
			intersectionCallback,
			observerOptions
		);
		observer.observe(componentRef.current);

		return () => {
			observer.unobserve(componentRef.current);
			observer.disconnect();
		};
	}, [animated]);

	return (
		<div className="overflow-hidden">
			<TilesContainer
				ref={componentRef}
				id={advanced?.anchor_name ? advanced.anchor_name : ""}
				className={animated}
			>
				{tiles.map((tile) => {
					return (
						<Tile key={tile.id} $bg_img={tile.image}>
							<h3 className="z-0 text-lg leading-4">
								{tile.title}
							</h3>
							<p className="z-0 text-sm text-grey3">
								{tile.subtitle}
							</p>
						</Tile>
					);
				})}
			</TilesContainer>
		</div>
	);
};

export default RenderTilesComponent;
