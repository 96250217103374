"use client";

import React, { useState } from "react";
import { IAccordion } from "./accordion.interfaces";
import { AllData } from "../../interfaces";
import { Box } from "@mui/material";
import RenderTextBlock from "../Text";
import "./accordion.styles.css";
type accordionProps = AllData<IAccordion>;

const RenderAccordionComponent = ({ data }: accordionProps) => {
	const { header, content, styling } = data;
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleAccordion = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<Box>
			<div
				className="accordion-title flex cursor-pointer items-center justify-between border-t-2 border-grey0 pt-4"
				onClick={toggleAccordion}
			>
				<h2
					className={`max-w-[75%] break-words text-base font-medium md:max-w-full ${
						isExpanded ? "font-semibold" : ""
					}`}
				>
					{header}
				</h2>
				<svg
					className={`h-4 w-4 transform transition-transform text-${
						styling?.chevron_color || "black"
					} min-w-[16px] ${isExpanded ? "rotate-180" : ""}`}
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</div>
			{isExpanded && (
				<div className="bg-white pt-4 pr-4">
					{content && <RenderTextBlock data={{ content: content }} />}
				</div>
			)}
		</Box>
	);
};

export default RenderAccordionComponent;
