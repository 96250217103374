"use client";
import RenderAccordionComponent from "@/components/Accordion";
import { flattenObject } from "@/utils/flattenObject";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import MapBlocks from "@/app/MapBlock";

const FAQ = (props) => {
	const {
		translations: {
			heading,
			searchPlaceholder,
			resultTitle,
			resultCount,
			backText,
		},
		data: faqBlocks,
	} = props;
	const [faqData, setFaqData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [selected, setSelected] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [searched, setSearched] = useState([]);
	const faqRef = useRef();

	useEffect(() => {
		const blocks = (faqBlocks?.content || []).filter(
			(b) => b.block_name == "faq"
		);
		const data = flattenObject(
			blocks.flatMap((b) => b.data.columns[0].components[1].data)
		);

		const extractedFaqs = {};
		Object.keys(data).forEach((key) => {
			if (key.endsWith("data.header") || key.endsWith("data.content")) {
				extractedFaqs[key] = data[key];
			} else if (key.endsWith("type") && data[key] === "accordion") {
				extractedFaqs[key] = data[key];
			}
		});

		const rawFaqData: any = {};
		Object.keys(extractedFaqs).forEach((key) => {
			if (key.endsWith("data.header")) {
				const baseKey = key.substring(0, key.indexOf(".data.header"));
				const contentKey = baseKey + ".data.content";
				rawFaqData[extractedFaqs[key]] = extractedFaqs[contentKey];
			}
		});
		setFaqData(rawFaqData);
	}, [faqBlocks?.content || []]);

	useEffect(() => {
		if (faqRef.current && selected) {
			const elem = Array.from(
				faqRef.current.querySelectorAll(".accordion-title")
			).find((e) => e.innerText.includes(selected));
			const offset =
				elem.getBoundingClientRect().top - window.innerHeight / 4;
			window.scrollTo({ top: offset, behavior: "smooth" });
			!elem.nextSibling && elem.click();
		}
	}, [selected]);

	const clearInput = () => {
		setSearchQuery("");
		setSuggestions([]);
		setSearched([]);
	};

	const handleInputChange = (e) => {
		const inputValue = e.target.value;
		const filtered: any = Object.entries(faqData).filter(
			(faq: string[]) =>
				faq[0].toLowerCase().includes(inputValue.toLowerCase()) ||
				faq[1].toLowerCase().includes(inputValue.toLowerCase())
		);
		setSearchQuery(inputValue);
		setSuggestions(filtered);
	};

	const handleKeyPress = (e) => {
		if (e.key.toLowerCase() === "enter") {
			setTimeout(() => {
				window.scrollBy(0, 340);
			}, 250);
			setSearched(suggestions);
			setSuggestions([]);
		}
	};

	const handleSuggestionClick = (e) => {
		setSelected(e.target.innerText);
		clearInput();
	};

	const RenderSearchResults = ({ results, clearInput }) => {
		return (
			<div className="mx-auto max-w-5xl md:px-2">
				<div className="pt-4 px-6 md:mx-24 md:px-0 md:pb-16">
					<span className="text-green1 ">
						<KeyboardArrowRightRoundedIcon
							className="rotate-180"
							fontSize="small"
						/>
						<button onClick={clearInput}>{backText}</button>
					</span>
					<div className="pb-2 pt-6">
						<h2>{resultTitle}</h2>
						<span className="text-grey3">
							{results.length} {resultCount}
						</span>
					</div>
					<div className="flex flex-col gap-4">
						{results.map((item, index) => (
							<RenderAccordionComponent
								key={`${item[0]}-${index}`}
								data={{ header: item[0], content: item[1] }}
							/>
						))}
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="bg-green5 flex h-[270px] flex-col items-center justify-end pb-8 md:py-14 md:h-96">
				<div className="relative w-full px-6 md:text-center md:max-w-4xl md:px-10">
					<h1 className="mb-9 mr-16 text-2xl md:mb-14 md:mx-auto md:w-[400px] md:text-[40px] md:leading-tight font-semibold">
						{heading}
					</h1>
					<span className="relative mx-auto flex items-center gap-2 bg-white px-4 shadow rounded">
						<SearchIcon className="text-grey2" />
						<input
							type="text"
							value={searchQuery}
							onChange={handleInputChange}
							onKeyUp={handleKeyPress}
							placeholder={searchPlaceholder}
							className="placeholder:text-grey2 w-full rounded border-none py-2 pr-4 text-base font-normal outline-none"
						/>
						<Image
							src={
								"https://d1gr3r269tafbs.cloudfront.net/nextjs/consumer/paula-faq_1707400723.png"
							}
							width={0}
							height={0}
							alt=""
							className="absolute bottom-6 right-0 w-24 h-24 md:w-40 md:h-36"
						/>
						{searchQuery && (
							<CancelIcon
								onClick={clearInput}
								className="text-grey2 hover:text-grey3 ml-auto cursor-pointer transition-all duration-150"
							/>
						)}
						{searchQuery.length > 0 && suggestions.length > 0 && (
							<>
								<div className="absolute left-0 top-14 z-10 w-full rounded bg-slate-900 bg-white p-4 shadow">
									{suggestions.map((suggestion, index) => (
										<div
											key={`faq-${Math.random() * index}`}
											onClick={handleSuggestionClick}
											className="border-grey2 mb-4 flex cursor-pointer justify-between border-b-[0.5px] pb-4 text-left text-lg leading-none last-of-type:mb-0 last-of-type:border-b-0 last-of-type:pb-0"
										>
											<p>{suggestion[0]}</p>
											<span className="max-w-5">
												<KeyboardArrowRightRoundedIcon />
											</span>
										</div>
									))}
								</div>
							</>
						)}
					</span>
				</div>
			</div>

			<div
				className="mx-auto max-w-5xl md:px-2 min-h-screen"
				ref={faqRef}
			>
				{searched.length > 0 ? (
					<RenderSearchResults
						results={searched}
						clearInput={clearInput}
					/>
				) : (
					<MapBlocks data={faqBlocks} serverIsMobileView={false} />
				)}
			</div>
		</>
	);
};
export default FAQ;
