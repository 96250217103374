import { Header } from "../header.interfaces";
import Link from "next/link";
import Image from "next/image";
import { getMediaSrc, localeRouteInterface } from "../../../utils";
import React from "react";
import { buildUrl } from "../../../utils/buildUrl";

export const Logo = ({
	link,
	media_src,
	mobileView,
	pageArgs,
	isConsumer,
}: {
	link?: string;
	media_src: Header["media_src"];
	mobileView: boolean;
	pageArgs: localeRouteInterface;
	isConsumer: boolean;
}) => {
	const mediaURL = getMediaSrc(media_src, mobileView);
	const logoSize = mobileView ? 40 : isConsumer ? 48 : 44;
	if (media_src) {
		return (
			<Link href={link || buildUrl("/", pageArgs)} prefetch={false}>
				<Image
					className="rounded md:rounded-small"
					src={mediaURL}
					alt={"Picnic logo"}
					width={logoSize}
					height={logoSize}
					loading="eager"
				/>
			</Link>
		);
	}
};
