import React from "react";
import { Card, Divider, ListItem } from "@mui/material";
import { ChevronRight, Group, LocationOn } from "@mui/icons-material";
import { Job } from "../joblist.interfaces";
import Link from "next/link";
import { getUrl } from "../../../utils/getJobUrl";
import { localeRouteInterface } from "../../../utils";

type jobTileProp = {
	job: Job;
	variant?: string;
	pageArgs?: localeRouteInterface | null;
};

const RenderJobTile = ({ job, variant, pageArgs }: jobTileProp) => {
	const { _id, name, data } = job;
	const { location, teams } = data;
	const url = getUrl(job, pageArgs);
	return (
		<Link href={`${url}`} className="w-full" prefetch={false}>
			<div className={`flex flex-col md:flex-row justify-between w-full`}>
				<div
					className={`flex items-center ${
						variant == "search_suggestion"
							? "md:w-[190px]"
							: "md:w-[289px]"
					} pb-2 md:pb-0`}
				>
					<strong>
						<h4>{name}</h4>
					</strong>
				</div>
				<div
					className={`flex items-center ${
						variant == "search_suggestion"
							? "md:w-[190px]"
							: "md:w-[212px]"
					}`}
				>
					<p>{teams}</p>
				</div>
				<div
					className={`flex items-center ${
						variant == "search_suggestion"
							? "md:w-[150px]"
							: "md:w-[212px]"
					} justify-between text-grey3 md:text-grey3`}
				>
					<p>{location?.city}</p>
				</div>
			</div>
		</Link>
	);
};

export default RenderJobTile;
