import React from "react";
import Image from "next/image";
import { getMediaSrc } from "../../../utils";
import { Container, FlexContainer } from "../../../styles/components";
import RenderTextBlock from "../../Text";

const RenderListItem = ({ item, mobileView, sizing }) => {
	const imageSizingMap = {
		sm: { width: mobileView ? 32 : 40, height: mobileView ? 32 : 40 },
		md: { width: mobileView ? 64 : 80, height: mobileView ? 64 : 80 },
		lg: { width: mobileView ? 98 : 192, height: mobileView ? 105 : 192 },
		xl: { width: mobileView ? 170 : 250, height: mobileView ? 190 : 280 },
	};

	const imageWrapperClass = {
		sm: `w-14 min-w-14 h-14 md:w-[72px] md:min-h-[72px] md:p-4 md:mb-6 md:self-center p-3 rounded-full bg-${
			item.background || "transparent"
		}`,
		md: "min-w-[64px] min-h-[80px]",
		lg: "min-w-[98px] min-h-[105px]",
		xl: "p-2 min-w-[170px] h-48 md:max-w-[250px] md:h-72 group-odd:-rotate-1 group-even:rotate-1",
	}[sizing];

	const imageClass = {
		sm: `!w-full`,
		md: "h-full object-contain",
		lg: "!h-full p-1.5 rounded-md object-cover object-top",
		xl: "!h-full p-1.5 rounded-md shadow object-cover object-top",
	}[sizing];

	const imageSize = imageSizingMap[sizing] || imageSizingMap["lg"];
	const mediaURL = getMediaSrc(item.media_src, mobileView);

	return (
		<div
			className={`flex gap-4 md:items-center md:flex-col md:max-w-[290px] group ${
				item.reversed ? "flex-row-reverse" : "flex-row"
			}`}
		>
			<span className={`md:p-4 md:self-center ${imageWrapperClass}`}>
				<Image
					src={mediaURL}
					width={imageSize.width}
					height={imageSize.height}
					alt={item.media_src[mobileView ? "mobile" : "desktop"].type}
					className={imageClass}
					loading="lazy"
				/>
			</span>
			<div className={`md:text-center self-center flex`}>
				<RenderTextBlock data={{ content: item.text }} />
			</div>
		</div>
	);
};

const RenderList = ({ items, advanced, styling, mobileView }) => {
	return (
		<Container id={advanced?.anchor_name || ""} className="list">
			<FlexContainer
				$flex_col={true}
				className={`justify-between gap-8 md:gap-4 md:flex-row md:max-w-6xl py-5 ${
					styling?.sizing === "md" ? "gap-4" : ""
				}`}
				$full_width
			>
				{items.map((item, index) => (
					<RenderListItem
						item={item}
						key={index}
						mobileView={mobileView}
						sizing={styling?.sizing}
					/>
				))}
			</FlexContainer>
		</Container>
	);
};

export default RenderList;
