export const HandleSizing = (
	sizing,
	mobileView
): { width: string; height: string } => {
	if (!sizing) {
		// If sizing is not provided, use default values
		return { width: "w-full", height: "h-full" };
	}

	if (mobileView && sizing.mobile) {
		// If mobileView is true and mobile exists, use mobile
		return {
			width: sizing.mobile.width || "w-full",
			height: sizing.mobile.height || "h-full",
		};
	} else if (sizing.desktop) {
		// If desktop exists, use desktop
		return {
			width: sizing.desktop.width || "w-full",
			height: sizing.desktop.height || "h-full",
		};
	} else if (sizing.mobile) {
		// If only mobile exists, use mobile
		return {
			width: sizing.mobile.width || "w-full",
			height: sizing.mobile.height || "h-full",
		};
	} else {
		// If neither desktop nor mobile is provided, use default values
		return { width: "w-full", height: "h-full" };
	}
};
