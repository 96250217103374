import dynamic from "next/dynamic";
import ConsumerComponentMap from "@/sites/consumer/components";

const ComponentMap = {
	...ConsumerComponentMap,
	deeplink: {
		element: dynamic(() => import("./components/Deeplink")),
		context: [],
	},
};

export default ComponentMap;
